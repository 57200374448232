* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-family: 'NH-Regular', sans-serif;
}

html,
body {
	background-color: $background;
	font-family: 'NH-Regular', sans-serif;
}

.container {
	min-height: 100vh;
	overflow: hidden;
	display: block;
	position: relative;
	padding-bottom: 200px;
}

main {
	max-width:1040px;
	margin: auto;
	margin-top: 120px;
}

