.influencers{
    &-header{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        select{
            -webkit-appearance: button;
            -moz-appearance: button;
            -webkit-user-select: none;
            -moz-user-select: none;
            -webkit-padding-start: 2px;
            -moz-padding-start: 2px;
            background-color: $secondary-grey; /* Fallback color if gradients are not supported */
            // background-image: url(../images/select-arrow.png), -webkit-linear-gradient(top, #E5E5E5, #F4F4F4); /* For Chrome and Safari */
            // background-image: url(../images/select-arrow.png), -moz-linear-gradient(top, #E5E5E5, #F4F4F4); /* For old Firefox (3.6 to 15) */
            // background-image: url(../images/select-arrow.png), -ms-linear-gradient(top, #E5E5E5, #F4F4F4); /* For pre-releases of Internet Explorer  10*/
            // background-image: url(../images/select-arrow.png), -o-linear-gradient(top, #E5E5E5, #F4F4F4); /* For old Opera (11.1 to 12.0) */
            // background-image: url(../images/select-arrow.png), linear-gradient(to bottom, #E5E5E5, #F4F4F4); /* Standard syntax; must be last */
            background-position: center right;
            background-repeat: no-repeat;
            border: 1px solid $secondary-grey;
            //border-radius: 2px;
            //box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
            color: $white;
            font-size: 12px;
            margin: 0;
            overflow: hidden;
            padding: 6px 20px 6px 10px;
            text-overflow: ellipsis;
            white-space: nowrap;
            border-radius: 24px;
        }  
        
    }
    &-keywords-container{
        width: 100%;
        padding: 0px 0px 30px 0px;
        display: flex;  
        flex-direction: row;
        flex-wrap: wrap;
        align-content: flex-start;
        justify-content: center;
        overflow-y:hidden;
        -webkit-mask-image: linear-gradient(to bottom, $primary-grey 80%, transparent 100%);
        mask-image: linear-gradient(to bottom, $primary-grey 80%, transparent 100%);
    }
    &-keyword{
        height:30px;
        color: $white;
        cursor: pointer;
        border: 2px solid $white;
        border-radius: 20px;
        padding: 4px 12px;
        margin: 5px;
        &:hover,
        &:focus{
          background: $secondary-grey;
          span{
            color: $white;
          }
        }
        &-filter{
            @extend .influencers-keyword;
            background-color: $secondary-grey;
            border: 2px solid $secondary-grey;
        }
    }
    &-keyword-selected{
        @extend .onboarding-keyword;
        background: $white;
        span{
          color: $primary-grey;
        }
    }
    &-filter{
        display:flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 2px;
        margin: 5px 0px 0px 0px;
        width: 100%;
        font-size: 16px;
        &-item{
            padding: 4px 10px;
            border: 2px solid $secondary-grey;
            box-sizing: border-box;
            border-radius: 24px;
            background: $secondary-grey;
            margin: 0px 5px;
            span{
              color: $white;
              line-height: 16px;
            }
        }
        &-overlay{
            position: fixed; /* Sit on top of the page content */
            display: block; /* Hidden by default */
            width: 100%; /* Full width (cover the whole page) */
            height: 100%; /* Full height (cover the whole page) */
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0,0,0,0.5); /* Black background with opacity */
            z-index: 9999; /* Specify a stack order in case you're using a different order for other elements */
            cursor: pointer; /* Add a pointer on hover */
            &-tab{
                float: right;
                background-color: $primary-grey;
                width: 25%;
                height: 100%;
                display: flex;
                flex-direction: column;
                overflow-y: auto;
                &-title{
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    color: $white;
                    margin: 40px 40px 6px 40px;
                    span{
                        font-size: 22px;
                    }
                }
                &-search{
                    margin: 0px 40px;
                    p{
                        margin: 0px 10px;
                        font-weight: $bold;
                        font-size: 16px;
                        line-height: 19px;
                    }
                    &-interests{
                        margin: 0px 40px;
                        p{
                            margin: 0px 10px;
                            font-weight: 700;
                            font-size: 16px;
                            line-height: 19px
                        }
                        &-list{
                            overflow-y: auto;
                            height: 32vh;
                            &-item{
                                display: flex;
                                color: $white;
                                margin: 8px 0px;
                                // span{
                                //     margin: 0px 20px
                                // }
                                .radio{
                                    color: $white;
                                    display: grid;
                                    grid-template-columns: min-content auto;
                                    grid-gap: 0.5em;

                                    .radio__input{
                                        display: flex;
                                        input{
                                            opacity: 0;
                                            width: 0;
                                            height: 0;
                                            &:checked + .radio__custom {
                                                background: -webkit-gradient(linear, left top, left bottom, from(#9a00ff), to(#fe00ee));
                                            }
                                        }
                                    }
                                    .radio__custom {
                                        display: block;
                                        width: 1em;
                                        height: 1em;
                                        border-radius: 4px;
                                        border: 0.1em solid currentColor;
                                    }

                                    .radio__cleared{
                                        display: block;
                                        width: 1em;
                                        height: 1em;
                                        border-radius: 4px;
                                        border: 0.1em solid currentColor;
                                    }
                                    .radio__label{
                                        line-height: 1;
                                    }
                                }
                            }
                        }

                    }
                    &-ER{
                        @extend .influencers-filter-overlay-tab-search;
                        height:145px;
                        margin: 0px 40px 12px 40px;
                        &-item{
                            @extend .influencers-filter-overlay-tab-search-interests-list-item;
                        }
                    }
                    &-followers{
                        @extend .influencers-filter-overlay-tab-search;
                        &-form-row{
                            display:flex;
                            flex-direction: row;
                            width:100%;
                            //margin: 0px 30px;
                            :first-child{
                                margin-right: 5px;
                            }
                        }
                    }
                    &-btn{
                        margin: 20px auto;
                        width: 70%;
                    }
                }
            }
        }
    }
    &-search{
        display: flex;
        justify-content: center;
        label{
            width: 60%;
        }

    }
    &-tabs{
        color: $secondary-white;
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 20px 0;
        &-item{
            cursor: pointer;
            padding: 5px;
            margin: 5px;
            border-bottom: 1px solid $secondary-white;
        }
        &-item-selected{
            color: $white;
            border-bottom: 1px solid $white;
        }
    }
    &-list{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        &-btn{
            z-index: 9999;
        }
        &-none{
            margin: auto;
            font-size: 18px;
        }
    }
    &-card{
        background-color: $primary-grey;
        color: $quaternary-white;
        margin: 20px 10px;
        padding: 10px;
        width: 450px;
        min-height: 116px;
        border-radius: 8px;
        &-header{
            display: flex;
            flex-direction: row;
            div:nth-child(3){
               cursor: pointer;
               display: flex;
               flex-direction: row;
               width: 25%;
               p{
                padding: 2px 5px;
               }
            }
            &-image{
                width:10%;
                img{
                    z-index: 9997;
                    position: absolute;
                    bottom:-10px;
                    left: -10px;
                    margin-left: -30px;
                    margin-top: -5px;
                    object-fit: cover;
                    height: 64px;
                    width: 64px;
                    border-radius: 50%;
                }
            }

            &-title{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 100%;
                margin: 6px 0px 0px 0px;
                &-name{
                    display: flex;
                    flex-direction: row;
                    .fa-user-tie{
                        margin: 2px 6px 0px 0px;
                    }
                    a{
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 24px;
                        width:100%;
                        cursor: pointer;
                        &:hover{
                            color: $secondary-white;
                        }
                    }
                }
            }
            &-fav{
                margin: 0px 4px 0px 0px;
            }

            &-niches{
                display:flex;
                flex-direction: row;
                align-items: center;
                padding: 0px;
                &-item{
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    padding: 0px 10px;
                    border: 2px solid #F1F1F1;
                    box-sizing: border-box;
                    border-radius: 16px;
                    background: #F1F1F1;
                    margin: 0px 5px;
                    span{
                      color: $primary-grey;
                      font-weight: bold;
                      font-size: 8px;
                      line-height: 16px;
                    }
                }
            }
            &-audience{
                margin: 8px 0px 0px 6px;
                display: flex;
                flex-direction: row;
            }
        }

        &-subheader{
            display: flex;
            flex-direction: row;
            margin: 6px 0px 0px 41px;
            p{
                font-weight: 700;
                font-size: 10px;
                line-height: 12px;
            }
        }

        &-body{
            &-row{
                display: flex;
                flex-direction: row;
                margin: 5px 0px 0px 10px;
                p{
                    margin: 5px 15px 0px 0px;
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 16px;
                }
            }
            &-socials{
                display: flex;
                flex-direction: row;
                font-size: 20px;
                a{
                    margin: 0px 5px 0px 0px;
                }
            }
            &-interests{
                display:flex;
                flex-direction: row;
                align-items: center;
                padding: 0px;
                width: 100%;
                font-size: 10px;
                &-item{
                    padding: 0px 10px;
                    border: 2px solid #F1F1F1;
                    box-sizing: border-box;
                    border-radius: 16px;
                    background: #F1F1F1;
                    margin: 2px 6px;
                    span{
                      color: $primary-grey;
                      font-weight: bold;
                      font-size: 11px;
                      line-height: 14px;
                    }
                }
            }
        }

        &-image{
            display: block; 
            height: 100%;
        }
        &-images{
            display: flex;
            flex-direction: row;
            padding: 5px;
            height: 120px;
            span{
                display:block;
                height: 100%;
                img{
                    display:block;
                    height: 100%;
                }
            }
            img{
                z-index:-9999;
                width: 100%;
                object-fit: cover;
            }
            a:first-child{
                img{
                    border-radius: 0 0 0 8px;
                }
            }
            a:nth-child(5){
                img{
                    border-radius: 0 8px 8px 0;
                }   
            }
        }
    }
}