.text-scroll {
  display: flex;
  position: relative;
  margin: auto;
  overflow: hidden;
  z-index: 1;
  height:16px;
  width:100px;
    &__title {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: flex-start;
    white-space: nowrap;
    transition: all 1s ease;
    & > div {
      display: flex;
      animation: scrollText 33s infinite linear;
    }
    p {
      font-size: 16px;
      margin: 0px 15px;
      color: rgba(255,255,255,1);
      transition: all 2s ease;
      }
  }
}

/*div:hover {
animation-play-state: paused;
}*/
@keyframes scrollText {
from   { transform: translateX(0%); }
to { transform: translateX(-50%); }
}