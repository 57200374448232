
.pagination {
    margin: auto;
    justify-content: center;
    display: flex;
    list-style: none;
    outline: none;
}
.pagination > .active > a{
    background-color: $white ;
    color: $orange;
}
.pagination > li > a{
    padding: 5px 10px;
    outline: none;
    cursor: pointer;
}
.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
    background-color: $white ;
    outline: none ;
}
.pagination > li > a, .pagination > li > span{
    color: $white
}
.pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span{
    border-radius: unset
}

.paginate{
    &-Element{
        text-align: center;
        color: $white;
    }
}

