h1{
    @include heading($x-large);
    padding-bottom: 10px;
}

h2{
   @include heading($large);
   padding-bottom: 10px;
}

legend, strong{
    @include heading($medium);
    line-height: 24px;
    display: block;
    padding-bottom: 5px;
}

p{
    color: $white;
    font-size: $small;
    font-weight: 400;
    line-height: 1.15;
    padding-bottom: 5px;
}

span{
    line-height: 1.15;
}

li{
    line-height: 168%
}

@font-face {
    font-family: 'NH-Regular';
    src: local('NH-Regular'), url('/fonts/55Rg/55Rg.woff2') format('woff2')
}

@font-face {
    font-family: 'NH-Medium';
    src: local('NH-Medium'), url('/fonts//65Md/65Md.woff2') format('woff2')
}

@font-face {
    font-family: 'NH-Bold';
    src: local('NH-Bold'), url('/fonts/75Bd/75bd.woff2') format('woff2')
}
