.loading{
    &-overlay{
        position: fixed; /* Sit on top of the page content */
        display: block; /* Hidden by default */
        width: 100%; /* Full width (cover the whole page) */
        height: 100%; /* Full height (cover the whole page) */
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba($primary-grey,0.2); /* Black background with opacity */
        opacity: 1;
        z-index: 9999; /* Specify a stack order in case you're using a different order for other elements */
        text-align: center;
        cursor: pointer; /* Add a pointer on hover */
        h1{
            margin: 100px 0px 0px 0px;
        }
        div{
            color: $orange;
            font-size: 80px;
        }
    }
}