.home{
    &-content{
        display:flex;
        flex-direction: row;
        align-items: flex-start;
        width: 100%;
    }
    &-inline{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        .primary-card{
            z-index:9994;
        }
    }
    &-campaigns{
        width: 100%;
    }
    &-inbound{
        text-align: center;
        width: 30%;
        margin-left: 20px;
        padding: 21px 18px 21px 24px;
        div{
            padding: 5px 0;
        }
    }
    &-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 8px;
        &-noCampaigns{
            p{
                line-height: 23.5px;
            }
            &-overlay{
                &-on{
                    z-index:9991;
                    @extend .overlay-on;
                }
                &-off{
                    @extend .overlay-off;
                }
                

            }
        }
    }
    &-campaign{
        display: flex;
        flex-direction: row;
        justify-content:space-between;
        background-color:$secondary-grey;
        border-radius: 16px;
        color: $white;
        padding: 15px;
        margin: 8px;
        width: 100%;
        &-date{
            color: $secondary-white;
        }
        &-actions{
            display:flex;
            flex-direction: row;
            button{
                display:flex;
                flex-direction: row;
                height: 30px;
                margin: 0 !important;
                padding: 15px 10px;
                span{
                    padding-left: 5px;
                }
            }
        }
        &-icon{
            color: $white;
            padding: 8px; 
        }
    }
    &-btn{
        &-container{
            position:relative;
            color: white;
            text-align: center;
            width: 30%;
        }
        &-label{
            position: absolute;
            //padding:50px;
            width: 90%;
            margin:6px 5%;
            border-radius: 8px;
            background: linear-gradient(345.17deg, #9A00FF 9.81%, #FE00EE 178.29%);
            img{
                position: absolute;
                left:46%;
                margin: -8px 10px 0px 0px;
            }
            &-txt{
                padding:6px;
                
                h4{
                    font-weight: $bold;
                    font-size: 18px;
                    line-height: 24px;
                }
                p{
                    margin:auto;
                    width: 70%;
                    font-weight: $bold;
                    font-size: 10px;
                    line-height: 12px;
                }
            }
        }
    }

    &-sponsorships{
        @extend .home-campaigns;
        .home-btn-container{
            display: flex;
            flex-direction: row;
            width: 50%;
            .btn-secondary{
                margin-left: 10px;
            }
        }
    }
    &-brandLink{
        @extend .onboarding;
    }
}

.no-overlay{
    z-index:auto !important;
}


