.header {
	background: $primary-grey;
	height: 72px;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 9990;

	&-content{
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding: 0 200px;
		max-width: 1440px;
		margin: auto;
		img {
			width: 150px;
			padding: 22px 0;
		}

		a,
		.header-logo {
			display: flex;
			flex-direction: row;
			align-items: flex-start;
			div {
				padding-top: 35px;
				padding-left: 10px;
			}
			span {
				color: $white;
				font-weight: 700;
			}
		}
		
		nav {
			display: flex;
			flex-direction: row;
			ul {
				padding-top: 12px;
				display: flex;
				list-style: none;
	
				li {
					a {
						color: $white;
						text-decoration: none;
						margin-right: 40px;
						font-size: $medium;
						font-weight: 700;
						&:hover,
						&:focus {
							color: $secondary-white;
						}
					}
				}
			}

		}
	}
	&-dropdown {
		img {
			object-fit: cover;
			height: 41px;
			width: 41px;
			padding: 0;
			border-radius: 50%;
		}
		&-userIcon {
			position: relative;
			right: 20px;
			color: $secondary-white;
			font-size: 32px;
			padding: 5px 0 15px 15px;
			cursor: pointer;

		}
		&-content {
			display:none;
			position:absolute;
			background: $secondary-grey;
			border-radius: 8px;
			div,
			a {
				color: $white;
				cursor: pointer;
				display: block;
				padding: 10px 20px;
				&:hover,
				&:focus {
					background: linear-gradient(83.66deg, #9A00FF 0%, #FE00EE 100%);
				}
			}
			:first-child {
				border-radius: 8px 8px 0 0;
			}
			:nth-child(2) {
				border-radius: 0 0 8px 8px;
			}
			&:before {
				content: '';
				position: absolute;
				top: -10px;
				right: 8px;
				border-bottom: solid 10px $secondary-grey;
				border-left: solid 8px transparent;
				border-right: solid 8px transparent;
			}
		}
	}

	&-dropdown:hover &-dropdown-content,
	&-dropdown:focus &-dropdown-content {
		display: block;
	}

	&-admin {
		position: absolute;
		display: flex;
		flex-direction: row;
		right: 7rem;
		p {
			cursor: pointer;
			margin: 0px 5px;
		}

		&-logout {
			font-weight: bold;
		}
	}

}

@media all and (max-width: 1920px) {
	.header-dropdown-content{
		top: 72px;
		right: 440px
	}
}

@media all and (max-width: 1840px) {
	.header-dropdown-content{
		top: 72px;
		right:360px
	}
}

@media all and (max-width: 1740px) {
	.header-dropdown-content{
		top: 72px;
		right:320px
	}
}

@media all and (max-width: 1640px) {
	.header-dropdown-content{
		top: 72px;
		right:280px
	}
}

@media all and (max-width: 1540px) {
	.header-dropdown-content{
		top: 72px;
		right:240px
	}
}

@media all and (max-width: 1440px) {
	.header-dropdown-content{
		top: 72px;
		right:200px
	}
}

@media all and (max-width: 1024px){
	.header{
		&-content{
			padding: 0px 30px;
		}
		&-dropdown-content{
			top: 72px;
			right:35px
		}
	}
}