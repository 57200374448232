@media all and (max-width: 768px) {
    .onboarding{
        width: 80%;
        
    }
}

@media all and (max-width: 425px) {
    .onboarding{
        padding: 80px 5px;
        background-color: $background;
    }
}

@media all and (max-width: 375px) {
    .onboarding{
        padding: 60px 0px;
    }
}

@keyframes fadeOut{
    from {opacity: 1;}
    to {opacity: 0;}
}