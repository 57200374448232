@mixin flexCenter($direction){
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: $direction;
}

@mixin heading($size){
    font-weight: 700;
    color: $white;
    font-size: $size;
}