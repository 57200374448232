.influencer-profile{
	color: $white;
	span{
		font-weight: $light;
	}
	&-header{
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		&-item{
			display:flex;
			flex-direction: row;
			span{
				width: 88px !important;
			}
			img{
				width: 88px;
				height: 88px;
				object-fit: cover;
				border-radius: 50%;
			}
		}

		&-title{
			margin: 10px 0px 0px 16px;
			h1{
				line-height: 32px;
				padding-bottom: 0px;
				margin: 0px 0px 4px 0px;
			}
			span{
				margin: 10px 0px 0px 0px;
				color: $secondary-white;
			}
			&-agency{
				display: flex;
				flex-direction: row;
				margin: 0px 0px 6px 0px;
				font-weight: bold;
				font-size: 18px;
				line-height: 24px;
				i{
					margin: 2px 5px 0px 0px;
				}
			}
		}

		&-actions{
			padding: 12px;
		
			div{
				display: flex;
				flex-direction: row;
				display: table;
				margin: 0 auto;
				padding: 3px;
				a{
					padding: 5px;
				}
				button{
					width: auto;
					padding: 0 30px;
					margin: 5px;
				}
			}
		}
		
	}

	&-content{
		display: flex;
		flex-direction: row;
		margin:auto;
		&-socials{
			display: flex;
			flex-direction: row;
			align-items: flex-start;
			width:100%;
			&-sidebar{
				width: 30%;
				//min-width: 100px;
				margin: 20px 0px 0px 0px;
				padding: 5px 15px 15px 15px;
				background-color: $primary-grey;
				border-radius: 8px;
				h2{
					//font-size: 20px;
					padding: 10px 0px 5px 0px;
				}
				&-section{
					padding: 0px 0px 5px 0px;
					&-tooltip{
						text-align: center;
						color: $secondary-white;
						font-size: $small;
					}
					p{
						font-style: normal;
						font-weight: normal;
						font-size: 14px;
						line-height: 168%;
					}
					
					a{
						display: block;
						text-align: left;
						font-size: $small;
						color: $secondary-white;
					}
					&-stat{
						padding: 10px;
						font-size: $large;
						font-weight: $bold;
						span{
							font-size: $small;
							font-weight: $light;
							display: block;
							padding-top: 5px;
						}
					}
					&-stat-wrap{
						padding: 10px;
						font-size: $large;
						font-weight: $bold;
						width: 50%;
						span{
							font-size: $small;
							font-weight: $light;
							display: block;
							padding-top: 5px;
						}
					}
					&-row{
						padding: 0 !important;
						display: flex;
						flex-direction: row;
					}
					&-incomplete{
						padding-bottom: 1000px;
					}
					&-discipline{
						display:flex;
						flex-direction: row;
						align-items: center;
						padding: 0px;
						margin: 5px 0px 0px 0px;
						width: 100%;
						font-size: 10px;
						&-item{
							padding: 0px 10px;
							border: 2px solid #F1F1F1;
							box-sizing: border-box;
							border-radius: 16px;
							background: #F1F1F1;
							margin: 0px 5px;
							span{
							  color: $primary-grey;
							  font-weight: bold;
							  font-size: 11px;
							  line-height: 14px;
							}
						}
					}
					&-interest{
						@extend .influencer-profile-content-socials-sidebar-section-discipline;
						&-item{
							@extend .influencer-profile-content-socials-sidebar-section-discipline-item;
						}
					}
	
					&-exclusion{
						@extend .influencer-profile-content-socials-sidebar-section-discipline;
						&-item{
							@extend .influencer-profile-content-socials-sidebar-section-discipline-item;
							background: $exclusion;
							border: 2px solid $exclusion;
							span{
								color: $white !important;
							}
	
						}
					}
				}
			}

			&-main{
				display: flex;
				flex-direction: column;
				max-width: 70%;
				min-width: 200px;
				#brands{
					padding: 10px;
					margin: 5px 0px 5px 30px;
					overflow-x: scroll;
					width:97%;
					&::-webkit-scrollbar {
						height: 0.3em;
					}

				}
				#feed{
					padding: 10px;
					margin: 5px 0px 5px 25px;
					width:99%;
				}
				#twoTitleLeft{
					margin-right: 30%;
				}
				&-row{
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					width: auto;
					&-title{
						padding: 20px 0px 10px 20px;
					}
					&-item{
						padding: 5px 0px 10px 40px;
						h3{
							font-weight: bold;
							font-size: 24px;
							line-height: 32px;
						}
						span{
							font-style: normal;
							font-weight: normal;
							font-size: 14px;
							line-height: 168%;
						}
						&-brand{
							display: flex;
							flex-direction: row;
							padding: 0px 10px;
							span{
								margin:20px 0px 0px 5px
							}
							img{
								height:78px;
								border-radius: 50px;
							}
						}
					}
					&-feed{
						overflow-x: scroll;
						display: flex;
						flex-direction: row;
						justify-content: space-between;
						div{
							font-weight: $bold;
							margin-right: 20px;
							img{
								display: block;
								padding-bottom: 8px;
								object-fit: cover;
								width: 250px;
								height: 250px;
							}
						}
						&::-webkit-scrollbar {
							height: 0.3em;
						}
	
						&-item{
							position: relative;
							max-width:300px;
							//height:350px;
							background-color: $primary-grey;
							border-radius: 10px 10px 10px 10px;
							padding-bottom: 8px;
							margin: 0px 0px 10px;
							&-link{
								position: absolute;
								padding-left: 6px;
								padding-top: 4px;
								iframe{
									border-radius: 10px 10px 0px 0px;
								}
								&-image{
									border-radius: 10px 10px 0px 0px;
									//width: 10px;
								}
								&-text{
									margin-left: 5px;
									height: 3em;
									overflow-y: hidden;
									text-overflow: ellipsis;
									display: -webkit-box;
									-webkit-line-clamp: 3; /* number of lines to show */
									-webkit-box-orient: vertical;
								}
								&-stats{
									width:100%;
									padding: 4px 4px;
									font-size: 14px;
									line-height: 16px;
									&-data{
										padding-bottom: 5px;
										span{
											font-weight: $bold;
											font-size: 18px;
											margin-left: 5px;
											margin-right: 5px;
										}
									}
								}
							}
						}
					}	
				}
			}
		}
		&-incomplete{
			width: 100%;
			p{
				text-align: center;
				margin: 30px 0px 0px 0px;
			}
			
		}
	}
}

.recharts{
	&-legend-item{
		span{
			color: $white !important;

		}
	}
	&-tooltip-label{
		color: $primary-grey !important;
	}
	&-custom-tooltip{
		background-color: $white;
		opacity: 0.7;
		padding: 5px;
		p{
			opacity: 1;
			color: $primary-grey;
		}
	}
}

