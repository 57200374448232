.Calendar{
    &Month_caption_1{
        strong{
            color: $primary-grey;
        }
    }

    &Day{
        &:hover{
            color: $primary-grey;
        }
    }

    &Day__selected,
    &Day__selected_span{
    background: $secondary-orange;
    border: 1px solid $secondary-white;
    &:hover,
    &:focus{
        background: $orange;
        border: 1px solid $secondary-white;
        color: $white;
    }
    }

    &Day__hovered_span:hover,
    &Day__hovered_span {
        background: $white;
        border: 1px solid $white;
        color: $primary-grey;
}
}

.DateInput_input{
    font-weight: 400;
    text-align:center;

    &__focused{
        border-bottom: 2px solid $orange;
    }
}

.DayPickerKeyboardShortcuts_show__bottomRight {
    display: none;
}

.DayPickerNavigation_button__default{
    background: $orange;

    .DayPickerNavigation_svg__horizontal {
        fill: $white;
    }

    &:active{
        background: $secondary-orange;
    }
}
