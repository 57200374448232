.page-not-found{
    text-align: center;
    h1{
        color: $secondary-white;
        font-style: normal;
        font-weight: bold;
        font-size: 148px;
        line-height: 186px;
    }
    h2{
        font-weight: bold;
        font-size: 64px;
        line-height: 81px;
    }
    p{
        margin: 0px 180px;
        font-size: 24px;
        line-height: 32px;
    }
    .btn-primary{
        margin: 20px 0px;
        width:18%
    }
}