.DateInput{
    width:105px;
    border-radius: 25px 0px 0px 25px;
    background: $primary-grey;
}

.SingleDatePickerInput{
    border-radius: 25px;
}

.DateRangePickerInput__withBorder, .SingleDatePickerInput__withBorder{
    border: none;
}

.DateInput_input{
    border-radius: 25px 0px 0px 25px;
    background: $secondary-grey;
    color: $white;
    font-weight: 700;
    font-size:14px;
    line-height: 16px;
    height: 36px;
}

.DateInput_input__focused{
    border-bottom: none;
}

.DateRangePickerInput, .SingleDatePickerInput{
    background-color: $secondary-grey;
    .DateRangePickerInput_arrow_svg, .SingleDatePickerInput_arrow_svg{
        fill: $white;
        height: 16px;
        stroke:$white;
        stroke-width: 8%;
    }
}

.DayPicker__withBorder{

    background: $secondary-grey; 
    
}
.DayPicker_transitionContainer{
    background: $secondary-grey;
}

.CalendarMonthGrid{
    background: $secondary-grey;
}

.CalendarMonth{
    background: $secondary-grey;
}

.CalendarMonth_caption{
    strong{
        color:$white;
    }
}

.DateRangePicker_picker, .SingleDatePicker_picker{
    background: $secondary-grey;
    border: 2px, solid, $secondary-grey;
    //border-radius: 40px;
}

.DayPicker_weekHeader_li{
    color:$white;
}

.DayPicker_transitionContainer{
    background: $secondary-grey;
}
.DateInput_fangStroke{
    stroke:$secondary-grey;
}

.DateInput_fangShape{
    fill:$secondary-grey;
}

.CalendarMonth_table{
    .CalendarDay__default{
        border:none;
        color: $white;
    }

    .CalendarDay{
        background: $secondary-grey;
        padding-top: 10px;
        
    }

    .CalendarDay__selected{
        background: $orange;
    }

    .CalendarDay__selected_start{
        border-radius: 20px 0px 0px 20px;
        background: $orange;
    }

    .CalendarDay__selected_span{
        background: $orange;
        border: 2px;
    }

    .CalendarDay__selected_end{
        background: $orange;
        border-radius: 0px 20px 20px 0px;
    }
}

.DayPickerNavigation_button{
    background: $white;
    border: none;
}

.DayPickerNavigation_button__horizontalDefault{
    border-radius: 8px;
    padding: 2px 2px;
    stroke: $primary-grey;
    stroke-width: 8%;
}

.DayPickerNavigation_svg__horizontal{
    height:14px
}

