@media all and (max-width: 1024px) {
    footer {
        height: 102px;
        nav {
            padding-top: 10px;
        }
    }

}

@media all and (max-width: 425px) {
    footer {
        padding: 0px 50px;
        div{
            font-size: 12px; 
        }
        nav{
            ul{
                li{
                    margin-left: 20px;
                    font-size: 14px; 
                }
                 
            }
        }

    }
}

@media all and (max-width: 380px) {
    footer{
        padding: 0px 20px;
    }
}

@media all and (max-width: 320px) {
    footer {
        padding: 0 20px;
    }
}