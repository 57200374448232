.settings{
    color: $white;
    display: flex;
    flex-direction: row;
    width: 100%;
    .btn-tertiary{
        &:hover,
        &:focus{
            color: $secondary-grey;
        }
    }
    &-steps{ 
        font-size: $small;
    }

    &-breadcrumbs{
        width: 30%;
        padding-right: 27px;
    }

    &-breadcrumb{
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-radius: 4px;
        padding: 6px 10px;
        margin: 5px 0;
        color: $tertiary-grey;
        div{
            padding-top: 2px;
        }
        &-active{
            background: $white;
            color: $primary-grey;
        }
        &-title{
            display: flex;
            flex-direction: row;
            font-weight: 700;
        }
        &-icon{
            font-size: 20px;
            margin-right: 8px;
        }
        &-check{
            font-size: 12px;
            margin-top: 4px;
        }
        &-check-complete{
            color: $orange;
        } 
    }

    &-card-container{
        width: 100%;
    }

    &-form-container{
        background: $primary-grey;
        color: $white;
        padding: 18px 24px;
        border-radius: 8px;
        fieldset{
            padding-bottom: 20px;
        }
        &-inline{
            display: flex;
            flex-direction: row;
            width: 50%;
            img{
                padding-right: 10px;
            }
        }
    }

    &-review-container{
        padding-top: 10px;
        strong{
            padding-top: 5px;
        }
        &-section{
            padding: 5px 0;
        }
        &-img{
            height: 50px;
            padding: 10px 0;
        }
    }

    &-img-container{
        text-align: center;
        img{
            border-radius: 50%;
            object-fit: cover;
            height: 72px;
            width: 72px;
        }
    }

    &-btn-container {
        display: flex;
        flex-direction: row;
        width: 50%;
        button{
            margin-right: 10px;
        }
    }

    &-icon-btn-container {
        cursor: pointer;
        text-align: center;
    }

    &-goals-cards{
        display: flex;
        flex-direction: row;
        padding-top: 10px;
    }

    &-deliverables-options{
        display: flex;
        flex-direction: row;
    }

    &-deliverables-option{
        cursor: pointer;
        background: $secondary-grey;
        margin: 4px;
        border-radius: 18px;
    
        strong{
            padding: 8px 18px;
        }
    }
    
    &-deliverables-option-selected{
      @extend .campaign-deliverables-option;
      background: $white;
      strong{
        color: $primary-grey;
        }
    }

    &-review-section{
        padding-bottom: 8px; 
    }

    &-influencer{
        display: flex;
        flex-direction: row;
        padding: 5px 5px;
        justify-content: space-between;
        &-name{
            line-height: 23.5px;
            font-size: 20px;
            font-weight: $bold;
        }
        &-subtitle{
            color:$secondary-white;
            line-height: 16px;
            font-size: 14px;
        }
        &-cost{
            margin-top: 15px;
            line-height: 23.5px;
            font-size: 20px;
            font-weight: $bold;
        }
        img{
            border-radius: 50%;
            object-fit: cover;
            height: 44px;
            width: 44px;
        }
        &-col{
            display: flex;
            flex-direction: row;
            &-col{
                padding: 3px 0px;
                margin-left: 15px;
            }
        }
    }

    &-cards{
        &-row{
            display: flex;
            flex-direction: column;
            &-item{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                background-color: $secondary-grey;
                padding: 10px 20px 10px 20px;
                margin: 5px 0px;
                border-radius: 16px;
                span{
                    font-weight: $bold;
                }
                img{
                    height: 19.58px;
                    padding: 0px 10px 0px 0px;
                }
                button{
                    margin: 0px;
                    height: 100%;
                    width: 90px;
                    svg{
                        margin: 0px 8px;
                    }
                }
                &-details{
                    display: flex;
                    flex-direction: row;
                    svg{
                        font-size: 18px;
                        margin: 0px 6px 0px 0px;
                    }
                }
            }
        }
    }

    &-payouts{
        &-filter{
            padding: 8px 0px;
            display: flex;
            flex-direction: row;
            .form-input{
                margin: 8px 6px 0px 0px;
                input{
                    font-size: 12px;
                    font-weight: 700;
                }
            }
            &-datePicker{
                @extend .campaign-form-container-calendar;
                margin: 8px 4px 0px 0px;
                width: 66% !important;
                .DateInput{
                    width: 90px;
                    background: $secondary-grey;
                }
                .DateInput_input{
                    font-size:12px;
                }
                svg{
                    font-size: 14px;
                    margin: 0px 2px 0px 10px !important;
                }
            }
            &-select{
                &-title{
                    display: none;
                }
            }
            select{
                height: 36px;
                -webkit-appearance: button;
                -moz-appearance: button;
                -webkit-user-select: none;
                -moz-user-select: none;
                -webkit-padding-start: 2px;
                -moz-padding-start: 2px;
                background-color: $secondary-grey; /* Fallback color if gradients are not supported */
                // background-image: url(../images/select-arrow.png), -webkit-linear-gradient(top, #E5E5E5, #F4F4F4); /* For Chrome and Safari */
                // background-image: url(../images/select-arrow.png), -moz-linear-gradient(top, #E5E5E5, #F4F4F4); /* For old Firefox (3.6 to 15) */
                // background-image: url(../images/select-arrow.png), -ms-linear-gradient(top, #E5E5E5, #F4F4F4); /* For pre-releases of Internet Explorer  10*/
                // background-image: url(../images/select-arrow.png), -o-linear-gradient(top, #E5E5E5, #F4F4F4); /* For old Opera (11.1 to 12.0) */
                // background-image: url(../images/select-arrow.png), linear-gradient(to bottom, #E5E5E5, #F4F4F4); /* Standard syntax; must be last */
                background-position: center right;
                background-repeat: no-repeat;
                border: 1px solid $secondary-grey;
                //border-radius: 2px;
                //box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
                color: $white;
                font-size: 12px;
                margin: 8px 4px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                border-radius: 24px;
            }  
            &-selections{
                span{
                    font-size: 12px;
                    line-height: 16px;
                    font-weight: 700;
                    padding: 4px 16px;
                    margin: 0px 3px;
                    border-radius: 18px;
                    color: $secondary-white;
                    background-color: $secondary-grey;
                    svg{
                        margin: 0px 0px 0px 6px;
                        color: $white;
                    }
                }
            }
        }
        &-main{
            margin: 10px 0px 0px 0px;
            &-title{
                display: grid;
                grid-template-columns: [first] 59% [second] 14% [third] 20%;
                h5{
                    font-size: 12px;
                    line-height: 16px;
                    font-weight: bold;
                }
            }
            &-content{
                height:180px;
                -ms-overflow-style: none;
                scrollbar-width: none;
                overflow-y: scroll;
                -webkit-mask-image: linear-gradient(to bottom, black 80%, transparent 100%);
                mask-image: linear-gradient(to bottom, black 0%, transparent 100%);
                &-item{
                    display: grid;
                    grid-template-columns: [first] 36px [second] 55% [third] 16% [fourth] 8% [fifth] 8% [fifth] 8%;
                    background-color: $secondary-grey;
                    padding: 6px 10px;
                    margin: 2px 0px;
                    border-radius: 8px;
                    &-title{
                        display: flex;
                        flex-direction: row;
                        margin: 4px 0px 0px 0px;
                        p{
                            padding:0px;
                        }
                        div{
                            margin: 0px 60px 0px 0px;
                        }
                    }
                    &-date{
                        margin: 4px 0px 0px 0px;
                    }
                    &-payment{
                        margin: 4px 0px 0px 0px;
                        svg{
                            margin: 0px 0px 2px 0px;
                        }
                    }
                    img{
                        margin: 8px 0px 0px 0px;
                        height: 24px;
                        width: 24px;
                        border-radius: 24px;
                    }
                    p{
                        font-size: 12px;
                        line-height: 16px;
                    }
                    span{
                        font-size: 9px;
                        line-height: 12px;
                    }
                    #disabled{
                        color: $tertiary-grey;
                    }
                }
                &-nav{
                    text-align: center;
                    position: relative;
                    bottom: 20px;
                }
                &-none{
                    color: $quaternary-white;
                    font-size: 12px;
                    line-height: 12px;
                    padding: 10px 0px 10px 4px;
                }
            }
            &-content::-webkit-scrollbar {
                display: none; 
            }
        }

    }
}