.modal{
    z-index: 9999;
    &-error{
        strong{
            color: $error;
        }
    }
    &-upload-header{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        color: $white;
        border-bottom: 1px solid $tertiary-grey;
        margin-bottom: 10px;
        padding-bottom: 10px;
        div{
            display: flex;
            flex-direction: row;
            width: 50%;
            span{
                @include heading($large);
                padding: 10px;
            }
        }
        button{
            height: 32px;
            width: 15%;
            margin: 5px;
        }
    }
    &-terms{
        &-header{
            color: $white;
            border-bottom: 1px solid $tertiary-grey;
            margin-bottom: 10px;
            padding-bottom: 10px;
            span{
                display: block;
                @include heading($large);
                padding: 10px 0;
                text-align:center;
            }
        }
        &-content{
            height: 86%;
            overflow-x: hidden;
            padding: 0px 4px 0px 0px;
            div{
                padding: 10px 0;
            }
        }
        &-content::-webkit-scrollbar {
            width: 8px;
        }
        &-content::-webkit-scrollbar-thumb, ::-webkit-scrollbar-track {
            background: $primary-grey;
        }
        &-content:hover::-webkit-scrollbar-thumb {
            background: $tertiary-grey; 
        }
        &-content:hover::-webkit-scrollbar-track {
            background: $secondary-grey;
        }
        &-content::-webkit-scrollbar-thumb:hover {
            background: $white;
        }

    }
    &-delete{
        &-content{
            padding: 10px;
            overflow: hidden;
            div{
                padding-bottom: 10px;
            }
            span{
                color: $orange;
            }
        }
    }
    &-offer{
        &-close{
            background-color: $white;
            float: right;
            border-radius: 50%;
            button{
                color: $primary-grey;
                margin: 0;
                width: 25px;
                height: 25px;
            }
        }
        &-header{
            display: flex;
            flex-direction: row;
            padding: 10px 0;
            color: $secondary-white;
            img{
                border-radius: 50%;
                object-fit: cover;
                height: 72px;
                width: 72px;
                margin: 0 10px;
            }
            span{
                display: block;
                @include heading($x-large);
                padding: 10px 0;
            }
            strong{
                display: inline;
            }
        }
        &-subheader{
            @include heading($medium);
            color: $secondary-white;
            padding-bottom: 10px;
            strong{
                @include heading($large);
            }
            span{
                display: inline;
                color: $white;
            }
        }
        &-pitch{
            padding-bottom: 20px;
        }
        &-breadcrumbs{
            @include heading($medium);
            color: $secondary-white;
            padding: 10px 0;
            span{
                color:$white;
            }

        }
        &-selection{
            height: 60%;
            overflow-x: hidden;
            padding: 0px 4px 0px 0px;
            img{
                display: block;
                margin: 0 auto;
                padding: 10px;
            }
            &-message{
                text-align: center;
                padding: 150px 150px 0;
                strong{
                    padding-bottom: 20px;
                }
                button{
                    width: 60%;
                }
            }
            &-total{
                @include heading($large); 
            }
            &-2col{
                display: flex;
                flex-direction: row;
                justify-content:space-between;
                padding: 10px 0;
                width: 100%;
                &-col{
                    padding-right: 10px;
                    width: 50% !important;
                    strong{
                        @include heading($large);
                        padding-top: 15px;
                    }
                    span{
                        line-height: 1.2;
                    }
                    select, input{
                        margin: 5px 0;
                        min-width: 120px;
                    }
                    label{
                        margin-right: 5px;
                    }
                    div{
                        display: flex;
                        flex-direction: row;
                        justify-content:space-between;
                        padding: 5px 0;
                    }
                }
            }
            &-tile{
                @include heading($small);
                background-color: $secondary-grey;
                border-radius: 8px;
                color: $secondary-white;
                cursor: pointer;    
                padding: 10px;
                margin: 10px 0;
                span{
                    @include heading($medium);
                    display: block;
                    padding-bottom: 10px;
                }
            }
            &-selected{
                @include heading($small);
                background-color: $white;
                color: $primary-grey;
                border-radius: 8px;
                cursor: pointer;    
                padding: 10px;
                margin: 10px 0;
                span{
                    @include heading($medium);
                    color: $primary-grey;
                    display: block;
                    padding-bottom: 10px;
                }
            }
            &-title{
                padding:5px 0px 10px 0px
            }
            &-general{
                textarea{
                    height: 100px;
                }   
                
            }

        }
        &-selection::-webkit-scrollbar {
            width: 6px;
        }
        &-selection::-webkit-scrollbar-thumb {
            background: $primary-grey;
        }
        &-selection::-webkit-scrollbar-track{
            background: $primary-grey;
        }
        &-selection:hover::-webkit-scrollbar-thumb {
            background: $tertiary-grey; 
        }
        &-selection:hover::-webkit-scrollbar-track {
            background: $secondary-grey;
        }
        &-selection::-webkit-scrollbar-thumb:hover {
            background: $white;
        }

        // &-selection::-webkit-scrollbar {
        //     width: 6px;
        // }

        // /* Track */
        // &-selection::-webkit-scrollbar-track {
        //     background: $secondary-grey;
        //     border-radius: 8px;
        // }
            
        // /* Handle */
        // ::-webkit-scrollbar-thumb {
        // background: $tertiary-grey;
        // border-radius: 8px;
        // }
        
        // /* Handle on hover */
        // ::-webkit-scrollbar-thumb:hover {
        // background: $white;
        // border-radius: 8px;
        // }

        &-advanced{
            display: flex;
            flex-direction: column;
            padding: 7px;
            p{
                font-weight: bold;
                font-size: 16px;
                line-height: 24px;
            }

            svg{
                font-size: 16px;
                line-height: 18px;
                margin: 4px 7px 0px 0px;
                color: $secondary-white
            }
            &-toggle{
                display: flex;
                flex-direction: row;
            } 
            &-options{
                display: flex;
                flex-direction: column;
                color: $white;
                margin: 8px 0px;
                // span{
                //     margin: 0px 20px
                // }
                svg{
                    color: $quaternary-white;
                    margin: 2px 7px 0px 0px;
                }
                .radio{
                    color: $white;
                    display: grid;
                    grid-template-columns: min-content auto;
                    grid-gap: 0.5em;

                    .radio__input{
                        display: flex;
                        input{
                            opacity: 0;
                            width: 0;
                            height: 0;
                            &:checked + .radio__custom {
                                background: $orange
                            }
                        }
                    }
                    .radio__custom {
                        display: block;
                        width: 1em;
                        height: 1em;
                        border-radius: 4px;
                        border: 0.1em solid currentColor;
                    }

                    .radio__cleared{
                        display: block;
                        width: 1em;
                        height: 1em;
                        border-radius: 4px;
                        border: 0.1em solid currentColor;
                    }
                    .radio__label{
                        line-height: 1;
                    }
                }
                &-item{
                    padding: 0px 0px 20px 0px;
                    display: grid;
                    grid-template-columns: [first] 120px [second] 110px [third] 50px!important ;
                    span{
                        font-weight: bold;
                        font-size: 12px;
                        line-height: 20px;
                    }
                    img{
                        width: 18px;
                        padding: 0px;
                        margin: 2px 0px 0px 20px
                    }
                    select{
                        -webkit-appearance: button;
                        -moz-appearance: button;
                        -webkit-user-select: none;
                        -moz-user-select: none;
                        -webkit-padding-start: 2px;
                        -moz-padding-start: 2px;
                        background-color: $secondary-grey; /* Fallback color if gradients are not supported */
                        // background-image: url(../images/select-arrow.png), -webkit-linear-gradient(top, #E5E5E5, #F4F4F4); /* For Chrome and Safari */
                        // background-image: url(../images/select-arrow.png), -moz-linear-gradient(top, #E5E5E5, #F4F4F4); /* For old Firefox (3.6 to 15) */
                        // background-image: url(../images/select-arrow.png), -ms-linear-gradient(top, #E5E5E5, #F4F4F4); /* For pre-releases of Internet Explorer  10*/
                        // background-image: url(../images/select-arrow.png), -o-linear-gradient(top, #E5E5E5, #F4F4F4); /* For old Opera (11.1 to 12.0) */
                        // background-image: url(../images/select-arrow.png), linear-gradient(to bottom, #E5E5E5, #F4F4F4); /* Standard syntax; must be last */
                        background-position: center right;
                        background-repeat: no-repeat;
                        border: 1px solid $secondary-grey;
                        width: 115px;
                        //border-radius: 2px;
                        //box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
                        color: $white;
                        font-size: 12px;
                        margin: 0;
                        overflow: hidden;
                        padding: 0px 10px 0px 10px;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        border-radius: 24px;
                    }  
                }
                &-content{
                    display: flex;
                    flex-direction: row;
                }
            }  
        }
        &-footer{
            position: absolute; 
            bottom: 10px; 
            width: 95%;
        }
        &-btn{
            display: flex;
            flex-direction: row;
            width: 50%;
            button{
                margin-right: 10px;
            }
        }
    }
    &-pay{
        &-header{
            @extend .modal-offer-header;
            &-fee{
                display: flex;
                flex-direction: row;
                p{
                    color:$tertiary-grey;
                    margin: 0px 6px 0px 0px;
                }
                span{
                    font-size: 16px;
                    padding: 0px;
                    margin: 0px 6px 0px 0px
                }
            }
        }
        &-complete{
            display: flex;
            flex-direction: column;
            align-items: center;
            p{
                font-weight: bold;
                font-size: 24px;
                line-height: 32px;
                margin: 150px 0px 10px;
            }
            svg{
                font-size: 100px;
            }
            
        }
        
        &-success{
            &-container{
                margin-top: 10rem;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
            &-text{
                line-height: 32px;
                padding: 40%, 0;
                font-size: $x-large;
                font-weight: $bold;
            }
            &-icon{
                font-size: 100px;
            }
        }
        &-error{
            &-text{
                margin-left: 10px;
                color: #EB5757;
            }
        }
        &-influencer{
            display: flex;
            flex-direction: row;
            padding: 5px 5px;
            justify-content: space-between;
            img{
                border-radius: 50%;
                object-fit: cover;
                height: 66px;
                width: 66px;
            }
            &-col{
                display: flex;
                flex-direction: row;
                align-items: center;
                &-col{
                    padding: 3px 0px;
                    margin-left: 15px;
                }
            }
            &-name{
                line-height: 23.5px;
                font-size: 20px;
                font-weight: $bold;

            }
            &-subtitle{
                color:$secondary-white;
                line-height: 16px;
                font-size: 14px;
            }
            &-bold{
                color: $white;
                font-weight: $bold;
            }
            &-btn{
                margin-top: 20px;
                height: 32px;
                width:100px;
                &-container{
                    display: flex;
                    flex-direction: row;
                    width: 200px;
                }
            }
            &-paid{
                font-weight: $bold;
                font-size: $medium;
                line-height: 60px;
                &-icon{
                    color: $orange;
                }
            }
        }
        &-pricing-breakdown{
            //@extend .modal-offer-selection-2col-col;
            strong{
                @include heading($large);
                padding-top: 15px;
            }

            div{
                display: flex;
                flex-direction: row;
                justify-content:space-between;
            }
            span{
                color: $tertiary-grey;

            }
            &-item{
                padding: 10px 0px;
            }
            &-fee{
                padding: 10px 0px 0px 0px;
                &-calculation{
                    margin: auto;
                    margin-left: 5px;
                }
            }
        }
        &-subscription{
            &-close{
                position: absolute;
                right: 3%;
                top: -1%
            }
            &-header{
                display: flex;
                flex-direction: row;
                padding: 10px 0;
                color: $secondary-white;
                img{
                    border-radius: 50%;
                    object-fit: cover;
                    height: 72px;
                    width: 72px;
                    margin: 0 10px;
                }
                span{
                    display: block;
                    @include heading($x-large);
                    padding: 10px 0;
                }
                strong{
                    display: inline;
                }
            }
            &-form{
                width:100%;
                p{
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 168%;
                }
                span{
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 19px;
                }
                input{
                    width: 100%;
                    margin: 2px 0px 10px 0px
                }
                &-row{
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    :first-child{
                        margin: 0px 5px 6px 0px;
                    }
                    &-item{
                        display: flex;
                        flex-direction: column;
                        width: 50%;
                        input{
                            width: 100%;
                        }
                    }
                }
            }
        }
        &-cards-row{
            @extend .settings-cards-row;
            flex-direction: column !important;
            overflow-y: scroll;
            &-item{
                display: flex;
                color: $white;
                margin: 8px 0px;
                // span{
                //     margin: 0px 20px
                // }
                .radio{
                    color: $white;
                    display: grid;
                    grid-template-columns: min-content auto;
                    grid-gap: 1em;

                    .radio__input{
                        display: flex;
                        input{
                            min-width: 0px;
                            opacity: 0;
                            width: 0;
                            height: 0;
                            &:checked + .radio__custom {
                                background: $orange
                            }
                        }
                    }
                    .radio__custom {
                        display: block;
                        width: 1em;
                        height: 1em;
                        border-radius: 8px;
                        border: 0.13em solid currentColor;
                        margin: 2px 0px 0px 0px;
                    }

                    .radio__cleared{
                        display: block;
                        width: 1em;
                        height: 1em;
                        border-radius: 4px;
                        border: 0.1em solid currentColor;
                    }
                    .radio__label{
                        display: flex;
                        flex-direction: row;
                        line-height: 1;
                        img{
                            padding: 0em 1em 0em 0em;
                        }
                        span{
                            font-weight: $bold;
                        }
                    }
                }
            }
        }
        &-stripe-error-text{
            color: $error;
        }
    }
    &-content-review{
        &-close{
            background-color: $white;
            float: right;
            border-radius: 50%;
            button{
                color: $primary-grey;
                margin: 0;
                width: 25px;
                height: 25px;
            }
        }
        &-header{
            display: flex;
            flex-direction: row;
            padding: 10px 0;
            color: $secondary-white;
            img{
                border-radius: 50%;
                object-fit: cover;
                height: 72px;
                width: 72px;
                margin: 0 10px;
            }
            span{
                display: block;
                @include heading($x-large);
                padding: 10px 0;
            }
            strong{
                display: inline;
            }
        }
        &-content{
            display: flex;
            flex-direction: column;
            max-height: 80%;
            img{
                min-height: 100%; 
                max-width:100%;
                object-fit: contain;
            }
            span{
                padding-top: 10px;
                margin: auto;
                
            }
        }
    }
    &-update-password{
        .form-input{
            .fa-eye{
                margin-top: 3px;
                float: right
            }
        }
        &-success{
            &-container{
                display:flex;
                flex-direction: column;
                margin: 100px 0px;
                align-items: center;
                span{
                    svg{
                        margin: 10px 0px;
                        font-size: 30px;
                    } 
                }
            }
            &-text{
                @extend .modal-pay-success-text
            }
        }
    }
    &-paymentSetup{
        &-close{
            @extend .modal-pay-subscription-close;
        }   
        &-header{
            @extend .modal-pay-subscription-header;
        }
        &-form{
            @extend .modal-pay-subscription-form;
            &-row{
                @extend .modal-pay-subscription-form-row;
                &-item{
                    @extend .modal-pay-subscription-form-row-item;
                    
                }
            }
        }
        &-breadcrumbs{
            @extend .modal-offer-breadcrumbs
        }
        &-stripe{
            &-error{
                .StripeElement{
                    border: 2px solid #EB5757 !important;
                    
                }
                &-container{
                    text-align: center;
                }
                &-text{
                    justify-content: center !important;
                    font-weight: normal !important;
                    color:#EB5757 !important;
                }
            }
        }
    }
    &-message{
        &-close{
            @extend .modal-offer-close
        }
        &-content{
            margin: 40px 0px;
            h3{
                margin: 0px 0px 20px 0px;
            }
        }
    }
}

.campaign-cropper{
    .cropper-view-box {
        box-shadow: 0 0 0 1px $orange;
        outline: 0;
    }

    .cropper-point{
        background-color: $orange
    }
}

.onboarding-cropper, .settings-cropper{
    //Image cropper modal styling
    .cropper-crop-box, .cropper-view-box, .cropper-face {
        border-radius: 50%;
    }
        
    .cropper-view-box {
        box-shadow: 0 0 0 1px $orange;
        outline: 0;
    }

    .cropper-point{
        background-color: $orange
    }

    .cropper-line{
        display: none;
    }

    .point-nw, 
    .point-ne, 
    .point-sw,
    .point-se {
        display: none;
    }
}

#fixed{
    position: fixed;
    left: 50%;
    right: 50%;
    width: 30vw !important;
    overflow-y: hidden;
}

#modal-offer-selection-2col-col-step1{
    margin-bottom: 40px;
}

