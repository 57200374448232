// Global
@import 'abstracts/_variables';
@import 'abstracts/_functions';
@import 'abstracts/_mixins';

@import './base/_reset.scss';
@import './base/_typography';

// Desktop
@import 'desktop/layout/_container';
@import 'desktop/layout/_header';
@import 'desktop/layout/_footer';

@import 'desktop/components/_badges';
@import 'desktop/components/_buttons';
@import 'desktop/components/calendar';
@import 'desktop/components/_cards';
@import 'desktop/components/_chat';
@import 'desktop/components/_counter';
@import 'desktop/components/_forms';
@import 'desktop/components/_links';
@import 'desktop/components/_loading';
@import 'desktop/components/_modal';
@import 'desktop/components/notification';
@import 'desktop/components/_progressCircle';
@import 'desktop/components/_scrollbar';
@import 'desktop/components/sidebarTile';
@import 'desktop/components/_stripe';
@import 'desktop/components/_paginate';
@import 'desktop/components/_settings';
@import 'desktop/components/_datepicker';
@import 'desktop/components/_gradient';
@import 'desktop/components/_overlay';
@import 'desktop/components/_textScroll';

@import 'desktop/pages/_home';
@import 'desktop/pages/_campaignCreate';
@import 'desktop/pages/_campaignManage';
@import 'desktop/pages/sponsorshipCreate';
@import 'desktop/pages/sponsorshipManage';
@import 'desktop/pages/influencerList';
@import 'desktop/pages/influencerProfile';
@import 'desktop/pages/_onboarding';
@import 'desktop/pages/influencerRedirect';
@import 'desktop/pages/_admin';
@import 'desktop/pages/_pageNotFound';
@import 'desktop/pages/_error';


// Mobile
@import 'mobile/components/_cards';
@import 'mobile/components/_chat';
@import 'mobile/components/_forms';
@import 'mobile/components/_modal';
@import 'mobile/components/_loading';

@import 'mobile/layout/_container';
@import 'mobile/layout/_footer';
@import 'mobile/layout/_header';

@import 'mobile/pages/_home';
@import 'mobile/pages/_onboarding';


hr{
    margin: 10px 0;
    border: .5px solid #727272;
}

.space-between{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}

.check-container{
    height: 2px
}

.check-selected{
    float: right;
    color: $orange;
}

.hidden{
    display: none;
}

.icon-social{
    margin-left: 8px
}

.disabled-link {
    pointer-events: none;
    color: $secondary-grey;
  }

@keyframes fadeOut {
    from {opacity: 1;}
    to {opacity: 0;}
}

.no-more-beta{
    text-align: center;
    img{
        margin: 10px 0px 0px 0px;
        height: 30px;
    }
}