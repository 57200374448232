// Table
@media all and (max-width: 768px) {

	.header {
		padding: 0 70px;
		&-dropdown &-userIcon {
			position: absolute;
			top:16px;
			right: 100px !important;
		}
		&-dropdown-content {
			right: 105px !important;
		}
	}
}

@media all and (max-width: 425px) {

	.header {
		padding: 0px;
		z-index: 9990;
		&-content{
			justify-content: start;
			&-tag{
				margin: 6px 0px 0px 5px;
			}
			span{
				color: $white;
				font-weight: 700;
				font-size: 14px;
			}
		}
		&-dropdown &-userIcon {
			position: absolute;
			right: 20px !important;
			top: 15px;
		}
		&-dropdown-content {
			right: 25px !important;
			a{
				position: absolute;
				left: 0px;
			}
		}
	}
	.header-logo {
		margin: auto;
	}
}
