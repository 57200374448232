@mixin no-select {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.notification-component {
    position: relative;
    color: $white;
    margin-right: 40px;

    &:hover,
    &:focus {
        color: $secondary-white;
    }

    & > div {
        cursor: pointer;
        @include no-select();
        font-weight: $bold;
    }
}

.notification-container {
    position: absolute;
    top: 125%;
    right: 5%;
    border-radius: 8px;
    
    box-shadow: rgba(0, 0, 0, 0.6) 0px 0px 12px 0px;
    
    #triangle-up {
        position: absolute;
        z-index: 1;
        top: -9px;
        right: 5px;
        width: 0;
        height: 0;
        border-left: 40px solid transparent;
        border-right: 40px solid transparent;
        border-bottom: 50px solid $secondary-grey;
    }
}

.notification-list {
    border-radius: 8px;
    position: relative;
    z-index: 2;
    background-color: $secondary-grey;
    
    width: 400px;
    height: 55vh;
    padding: 5px 0px;

    overflow-x: hidden;
    overflow-y: scroll;
}

.notification-list::-webkit-scrollbar {
    width: 8px;
}
.notification-list::-webkit-scrollbar-thumb {
    background: $secondary-grey;
}
.notification-list::-webkit-scrollbar-track{
    background: $secondary-grey;
}
.notification-list:hover::-webkit-scrollbar-thumb {
    background: $secondary-white; 
}
.notification-list:hover::-webkit-scrollbar-track {
    background: $tertiary-grey;
}
.notification-list::-webkit-scrollbar-thumb:hover {
    background: $white;
}
    

.notification-item {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    padding: 15px 15px;
    font-family: 'Raleway', sans-serif;

    p {
        width: 100%;
    }

    .notification-image-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 14%;
            
        img {
            all: unset;
            width: 50px;
            height: 50px;
            border-radius: 100%;
            object-fit: cover;
        }

    }

    .notification-image-alt-container {
        @extend .notification-image-container;
        
        img {
            border-radius: 16px;
        }
    }
    
    .notification-info {
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        box-sizing: border-box;
        padding-left: 15px;

        p {
            display: block;
            font-size: $medium;
            color: $white;
            padding: 0px;
        }

        b {
            font-weight: $bold;
        }
        
        span {
            display: block;
            font-size: small;
            color: $tertiary-grey;
        }
    }
}
.no-notifications {
    position: absolute;
    top: 50%;
    left: 50%;

    color: $tertiary-grey;

    transform: translate(-50%, -50%);
}

.link-wrapper {
    all: unset
}

.notification-option {
    font-weight: 800;
    font-size: $small;
    display: block;
    position: relative;
    padding-left: 25px;
    margin-bottom: 5px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        &:checked {
            & ~ .checkmark {
                border-color: $white;
                border-style: solid;
                border-radius: 3px;
                border-width: 2px;
                background-color: $white;
                
                &:after {
                    display: block;
                }
            }
        }
    }

    .checkmark {
        position: absolute;
        top: 3px;
        left: 0;
        height: 18px;
        width: 18px;
        border-color: $white;
        border-style: solid;
        border-radius: 3px;
        border-width: 3px;
        background-color: $primary-grey;

        &:after {
            content: "";
            position: absolute;
            display: none;
            
            left: 3px;
            top: 0px;
            width: 4px;
            height: 9px;
            border: solid $secondary-grey;
            border-width: 0 4px 4px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }
}