.loading{
    margin-top: 200px;
    text-align: center;
    div{
        font-size: 80px;
    }
    &-manage-campaign{
        color: $orange;
        text-align: center;
        font-size: 80px;
        margin-top: 100px;
        &-contracts{
            color: $orange;
        }
    }
    &-modal{
        &-message{
            color: $orange;
            font-size: 18px;
        }
        &-pay{
        color: $orange;
        font-size: $x-large;
        margin-top: 20px;
        margin-bottom: 20px;
        position: relative;
        left: 46%;
        &-container{
            display: flex;
            justify-content: center;
        }
        &-release{
            @extend .loading-modal-pay;
            left: 0;
            margin: 60px;
            font-size: 80px;
            &-container{
                display: flex;
                justify-content: center;
            }
        }
        }
        &-update-password{
            @extend .loading-modal-pay;
            font-size: 80px;
            margin-top: 60px;
            &-btn{
                color:$white;
                font-size: 22px;
            }
        }
        &-btn{
            @extend .loading-modal-pay;
            width:50%
        }
        &-offer{
            &-btn{
                color: $orange;
                font-size: 20px;
            }
        }
    }
    &-offer-modal{
        &-buttons-container{
            color: $orange;
            font-size: $x-large;
            text-align: center;
            margin-top: 24px;
            margin-bottom: 25px;
            
        }
    }
    &-onboarding{
        @extend .btn-primary;
        color: $orange;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
    }
    &-influencers{
        margin-top: 100px;
        margin-bottom: 300px;
        text-align: center;
        div{
            color: $orange;
            font-size: 80px;
        }
        &-card{
            display: flex;
            flex-direction: row;
            justify-content: center;
            &-container{
                margin: 5px 5px 0px 0px;
                color: $orange;
            }
        }
        &-fav{
            color: $orange;
        }
        &-paginate{
            color: $orange;
            font-size: 26px;
        }
    }
    &-sponsorship{
        &-influencer{
            margin-top: 20px;
            margin-bottom: 20px;
            text-align: center;
            div{
                color: $orange;
                font-size: 60px;
            }
        }
    }
    
    &-modal-offer{
        @extend .loading-manage-campaign;
    }

    &-settings{
        @extend .loading-manage-campaign;
        margin: 40px 0px 20px 0px;
        font-size: 40px;
        &-btn{
            color: $white;
            font-size: 22px;
        }
        &-payment{
            @extend .loading-settings;
            margin: 10px 0px 30px 0px;
        }
        &-image{
            font-size: 36px;
            height: 72px;
            color: $orange;
            &-container{
                padding: 18px 0px 0px 0px;
            }
        }
    }

    &-admin{
        text-align: center;
        font-size: 50px;
        color: $orange;
    }
}

//messages loading screen
.chats__decorator-message{
    .loading{
        margin:0px;
        .fa-gradient{
            font-family:"Font Awesome 5 Free" !important;
        }
    }
}

.filepicker__filelist{
    .loading-chat{
        margin:0px;
        .fa-gradient{
            font-family:"Font Awesome 5 Free" !important;
        }
    }
}

