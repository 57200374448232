.counter{
    &-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    }

    &-btn-container{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        button{
            margin: 0;
            height: 3px;
            margin-left: 15px;
        }
        }
}