
.custom-chat-options{
    cursor: pointer;
    position:absolute;
    right:20px;
    top:70px;
    background: $secondary-grey;
    padding: 10px;
    z-index: 100;
    border-radius: 8px;
    div{
        color: $white;
    }
    &:after{
        content: " ";
        position: absolute;
        bottom: 100%;  /* At the top of the tooltip */
        right: 10%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent $secondary-grey transparent;
    }
}

.cometchat{
    position: absolute;
    background: none;
    color: $white;
    span{
        color:$white;
    }
    .unified__sidebar{
        border: none;
        .chats{
            height: 100%;
            div{
                border-bottom: none;
            }
            &__header{
                @include heading($large);
                .header__close{
                    display: none;
                }
            }
            &__decorator-message{
                padding: 20px;
                .decorator-message{
                    font-weight: normal;
                }
            }
            
            &__list{
                .list__item{
                    border-radius: 8px;
                    background: $secondary-grey;
                    margin: 20px;
                    padding: 10px;
                    height: 60px;
                    width: 250px;
                    &__thumbnail{
                        width: 50px;
                        height: 50px;
                        img{
                            background-color: $white;
                            margin-top: -15px;
                            margin-left: -15px;
                            border-radius: 64px !important;
                        }
                    }
                }

                .list__item__details{
                    .item__details_block_two{
                        .item__details__last-message{
                            color: $white;
                        }
                    }
                }
            }
        }
    }

    .unified__main{
        min-height: 500px;
        height: auto;
        margin: 12px;
        .main__chat{
            padding: 10px;
            background: $primary-grey;
            border-radius: 8px;
            .chat__composer{
                background: $primary-grey;
                .composer__input{
                    .input__inner{
                        display: flex;
                        flex-direction: row;
                        background: $primary-grey;
                        border: none;
                        .input__message-input{
                            background: $secondary-grey;
                            border-radius: 18px;
                            color: $white;
                        }
                        .input__sticky{
                            font-size: $large;
                            padding: 10px 0px;
                            color: $white;
                        }
                    }
                }
            }
            .chat__header{
                padding: 10px;
                border-bottom: 2px solid $secondary-grey;
                background: $primary-grey;
                .chat__options{
                    span{
                        cursor: pointer;
                    }
                }
                // .chat__details{

                // //     .chat__thumbnail{
                // //         img{
                //             // border-color: blue
                // //         }
                // //     }
                // }
            }
            .chat__list{
                background: $primary-grey;
                .list__wrapper{
                    .message__deleted{
                        display: none;
                    }
                }
            }
        }
    }
    .css-xtmqvi{
        margin: 0px 0px 0px 10px
    }
    .css-6rqjr4{
        width: 40px
    }
    .css-1vnotfc{
        width:70px;
        opacity:1;
        margin: 0px 0px 0px 10px
    }
    .css-nok4yu{
        background: $primary-grey;
    }
}

.message__date{
    span{
        background: none;
    }
}

.receiver__message__container{
    .message__wrapper{
        .message__txt__wrapper{
            background: $secondary-grey;
            .message__txt{
                font-weight: $bold;
                font-size: 13px;
                line-height: 16px;
                padding-bottom: 0;
            }
        }
    }
}

.sender__message__container{
    .message__wrapper{
        .message__txt__wrapper{
            background: $tertiary-white;
            .message__txt{
                color: $primary-text;
                font-weight: $bold;
                font-size: 13px;
                line-height: 16px;
                text-align: right;
                padding-bottom: 0;
            }
        }
        .message__file__wrapper{
            background: $tertiary-white;
            a{
                color: $primary-text;
                font-weight: $bold;
                font-size: 12px;
                line-height: 16px;
            }
        }
    }
    .message__info__wrapper{
        .message__timestamp{
            img{
                display: none;
            }
        }
    }
}

//chat sidebar scrollbar styling
.chats__list{
    height:400px !important;
}
.chats__list::-webkit-scrollbar {
    width: 6px;
}
.chats__list::-webkit-scrollbar-thumb {
    background: $background;
}
.chats__list::-webkit-scrollbar-track{
    background: $background;
}
.chats__list:hover::-webkit-scrollbar-thumb {
    background: $tertiary-grey; 
}
.chats__list:hover::-webkit-scrollbar-track {
    background: $secondary-grey;
}
.chats__list::-webkit-scrollbar-thumb:hover {
    background: $white;
}

//main chat window scrollbar styling
.list__wrapper::-webkit-scrollbar {
    width: 6px;
}
.list__wrapper::-webkit-scrollbar-thumb {
    background: $primary-grey;
}
.list__wrapper::-webkit-scrollbar-track{
    background: $primary-grey;
}
.list__wrapper:hover::-webkit-scrollbar-thumb {
    background: $tertiary-grey; 
}
.list__wrapper:hover::-webkit-scrollbar-track {
    background: $secondary-grey;
}
.list__wrapper::-webkit-scrollbar-thumb:hover {
    background: $white;
}


.list__item{
    width: 244px !important;
}
.unified__main{
    height:500px !important;
}

// HIDE
.user__status,
.presence,
.button__emoji,
.emoji-mart{
    display: none;
}

.chat__options{
    span{
        display:none;
    }
}

