.primary-card{
    background: $primary-grey;
    border-radius: 8px;
    margin: 25px 0;
    padding: 21px 24px;
    //height: 100%;
}

.secondary-card{
    background: $secondary-grey;
    border: 2px solid $secondary-grey;
    border-radius: 16px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    margin: 10px;
    padding: 18px 15px;
    text-align: center;
    width: 100%;
    &:hover,
    &:focus{
        border: 2px solid $white;
    }
}

.secondary-card-selected{
    @extend .secondary-card;
    background: $white;
    border: 2px solid $white;
    strong, p{
        color: $primary-grey;
    }

}


