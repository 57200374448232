.influencer-redirect{
    display: flex;
    flex-direction: column;
    text-align: center;
    h3{
        color: $white;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        margin: 0px 0px 20px 0px;
    }
    p{
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        padding: 0px 0px 20px 0px;
        margin: auto;
        text-align: center;
        width:80%;
    }
    &-btns{
        width: 400px;
        margin: auto;
        display: flex;
        flex-direction: row;
    }
    button{
        width:100%;
        margin: 0px 5px;
    }
}