/* width */
::-webkit-scrollbar {
  width: 10px;
}
  
/* Track */
::-webkit-scrollbar-track {
  background: $primary-grey;
  border-radius: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $tertiary-grey;
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $white;
  border-radius: 8px;
}

// Disappearing scrollbar code
// ::-webkit-scrollbar {
//   width: 6px;
// }
// ::-webkit-scrollbar-thumb, ::-webkit-scrollbar-track {
//   background: $primary-grey;
// }
// :hover::-webkit-scrollbar-thumb {
//   background: $tertiary-grey; 
// }
// :hover::-webkit-scrollbar-track {
//   background: $secondary-grey;
// }
// ::-webkit-scrollbar-thumb:hover {
//   background: $white;
// }