.FormRowInput,	
.__PrivateStripeElement-input {	
	border: 2px solid $secondary-grey;	
	background: #474747;	
	border-radius: 18px;	
	color: $white;	
	height: 36px;	
	width: 100%;	
	padding: 7px 10px;	
	margin: 8px 0 20px 0;	
}	

.AppWrapper,	
Form {	
	width: 100%;	
}	

div {	
	.__PrivateStripeElement {	
		color: $white;	
		iframe {	
			width: 300px !important;	
		}	
	}	
}	

.StripeElement {	
	display: block !important;
	border: 2px solid $secondary-grey;	
	background: #474747;	
	border-radius: 18px;	
	color: $white;	
	height: 36px;	
	width: 100%;	
	padding: 7px 10px  !important;	
	margin: 5px 0px 10px ;	
}	

.StripeElement--complete {	
	background-color: $secondary-grey !important;	
}	

.StripeElement--webkit-autofill {
    background-color: $secondary-grey !important;
}

.StripeElement--invalid{
	border: 2px solid $error;
}

.StripeElement--focus{
	border: 2px solid $primary-grey;
}


