@mixin positionBadge($top, $right){
    top: $top;
    right: $right;
}

.badge {
    position: absolute;
    display: inline-block;
    transform: translate(50%, -50%);

    @include positionBadge(50%, 50%);

    font-size: $x-small;
    font-weight: $bold;
    color: $white;

    background: linear-gradient(83.66deg, #9A00FF 0%, #FE00EE 100%);
    border-radius: 16px;
    // cursor: pointer;
}

.badge-notification {
    @extend .badge;
    @include positionBadge(4px, -3px);

    height: 11px;
    width: 11px;
    border-radius: 100%;

    border-style: solid;
    border-width: 2px;
    border-color: white;
}

.badge-notification-item {
    @extend .badge-notification;
    @include positionBadge(20%, 3%);

    height: 12px;
    width: 12px;
}

.badge-component {
    @extend .badge;
    padding: 6px 12px;
}

.badge-component-campaign {
    @extend .badge-component;
    @include positionBadge(0px, 0px);
    transform: translate(0%, -50%)
}

.badge-component-avatar {
    @extend .badge-component;
    @include positionBadge(90%, 50%);
    font-size: 11px;
    padding: 5px 8px;
}

.badge-component-content {
    @extend .badge-component;
    @include positionBadge(0px, 0px);
}