.sponsorship{
    color: $white;
    display: flex;
    flex-direction: row;
    width: 100%;
    &-steps{ 
        font-size: $small;
    }

    &-breadcrumbs{
        width: 30%;
        padding-right: 27px;
    }

    &-breadcrumb{
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-radius: 4px;
        padding: 6px 10px;
        margin: 5px 0;
        color: $tertiary-grey;
        div{
            padding-top: 2px;
        }
        &-active{
            background: $white;
            color: $primary-grey;
        }
        &-title{
            display: flex;
            flex-direction: row;
            font-weight: 700;
        }
        &-icon{
            font-size: 20px;
            margin-right: 8px;
        }
        &-check{
            font-size: 12px;
            margin-top: 4px;
        }
        &-check-complete{
            color: $orange;
        } 
    }

    &-card-container{
        width: 100%;
    }

    &-form-container{
        background: $primary-grey;
        color: $white;
        padding: 18px 24px;
        border-radius: 8px;
        fieldset{
            padding-bottom: 20px;
        }
        &-inline{
            display: flex;
            flex-direction: row;
            width: 50%;
            img{
                padding-right: 10px;
            }
        }
        &-calendar{
            background-color: $secondary-grey;
            height: 36px;
            width: 100%;
            border-radius: 25px;

            svg{
                margin: 0px 15px 0px 20px;
            }
            :first-child{
                svg{
                    margin: 0px ;
                }
            }
        }
        &-strong{
            margin: 20px 0px
        }
    }

    &-influencer{
        &-list{
            display: flex;
            flex-direction: column;
            min-height: 40px;
            max-height: 40vh;
            overflow-y: scroll;
            &-item{
                display: flex;
                flex-direction: row;
                padding: 8px;
                width: 100%;
                img{
                    width: 44px;
                    height: 44px;
                    object-fit: cover;
                    border-radius: 50%;
                }
                p{
                    padding: 3px;
                    margin: 11px 0px 11px 11px;
                    width: 100%;
                }

                //checkbox container
                &-input{
                    display: block;
                    position: relative;
                    margin:  15px 0px 15.5px 0px;
                    padding-left: 25px;
                    cursor: pointer;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;

                    //hide original checkbox
                    input{
                        position: absolute;
                        opacity: 0;
                        cursor: pointer;
                        height: 0;
                        width: 0;
                    }

                    //create custom checkbox
                    &-checkbox {
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 16px;
                        width: 16px;
                        background-color: $primary-grey;
                        border: solid rgba(241, 241, 241, 1);
                        border-radius: 25px;
                        border-width: 2px;
                        &-selected{
                            @extend .sponsorship-influencer-list-item-input-checkbox;
                            background-color: $orange;
                            border: solid $orange;
                            border-radius: 25px;
                            border-width: 2px;
                            //content: url();
                        }
                    }

                    input:hover ~ .sponsorship-influencer-list-item-input-checkbox{
                        background-color: $tertiary-grey;
                    }

                }
            }
        }
    }

    &-review-container{
        padding-top: 10px;
        strong{
            padding-top: 5px;
        }
        &-section{
            padding: 5px 0;
        }
        &-img{
            height: 50px;
            padding: 10px 0;
        }
    }

    &-img-container{
        text-align: center;
        img{
            border-radius: 50%;
            object-fit: cover;
            height: 72px;
            width: 72px;
        }
    }

    &-btn-container {
        display: flex;
        flex-direction: row;
        margin-top: 10px;
        width: 50%;
        button{
            margin-right: 10px;
        }
    }

    &-icon-btn-container {
        cursor: pointer;
        text-align: center;
    }

    &-goals-cards{
        display: flex;
        flex-direction: row;
        padding-top: 10px;
    }

    &-deliverables{
        &-options{
            display: flex;
            flex-direction: row;
        }
        &-option{
            cursor: pointer;
            background: $secondary-grey;
            margin: 4px;
            border-radius: 18px;
            strong{
                padding: 8px 18px;
            }
            &-selected{
                @extend .campaign-deliverables-option;
                background: $white;
                strong{
                  color: $primary-grey;
                  }
            }
        }
        &-title{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            &-remove{
                display: flex;
                flex-direction: row;
                p{
                    margin:0px 6px 0px 0px;
                }
            }
        }
        &-section{
            display:block;
            &-hidden{
                display:none;
            }
        }
        &-add{
            display: flex;
            flex-direction: row;
            justify-content: center;
            width: 100%;
            margin: 20px 0px 0px 0px;
            h2{
                font-size: $medium;
                line-height: 19px;
                margin-left: 5px;
            }
            &-box{
                display: flex;
                flex-direction: row;
                justify-content: center;
                .secondary-card{
                    max-width: 260px;
                }
                &-exit{
                    float:left;
                    button{
                        margin: 0px;
                        height:0px;
                    }  
                }
            }
        }
    }

    &-review-section{
        padding-bottom: 8px; 
        &-influencer{
            display:flex;
            flex-direction: row;
            padding: 10px;
            img{
                width: 64px;
                height: 64px;
                object-fit: cover;
                border-radius: 50%;
            }
            div{
                margin: 0px 0px 0px 20px;
            }
            p{
                font-size: 16px;
                font-weight: 700;
                padding: 3px;
                width: 100%;
            }
            button{
                height:36px;
                width:144px;
                font-size: 16px;
            }
        }
    }
}