$image-size: 70px;
$msg-image-size: 150px;
$table-radius: 10px;
$badge-radius: 30px;

.users-list {
  overflow-y: auto;

  thead {
    background-color: rgba(243, 243, 243, 0.219);
  }

  tbody {
    background-color: rgba(83, 83, 83, 0.294);
    border-radius: $table-radius;
  }
  
  table {
    width: 100%;
    border-radius: $table-radius;
    overflow: hidden;
  }

  td, th {
    padding: 5px;
    color: white;
    text-align: center;
    vertical-align: middle;
  }
  
  tr:nth-child(even) {
    background-color: rgba(88, 88, 88, 0.219);
  }

  .name-col {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    text-align: left;
    width:100%;
    div {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      padding: 10px 0;
      h5{
        margin: 24px 0px 0px 0px;
      }
      p{
        margin: 24px 0px 0px 6px;
      }
    }
    img {
      height: $image-size;
      width: $image-size;
      //border-radius: $image-size/2;
      margin-right: 15px;
    }
    .has-insta {
      border: 2px solid $orange;
      padding: 5px;
      cursor: pointer;
    }
    .btn-primary{
      margin: 30px 0px 0px 20px;
      font-size: 12px;
      height:24px;
      width:120px;
    }
    &-details{
      display: flex;
      flex-direction: column !important;
      padding: 5px 0px !important;
      span{
        margin: 0px 0px 2px 0px;
      }
      &-rep{
        display: flex;
        flex-direction: row;
        span{
          margin:0px 8px 0px 0px;
        }
      }
    }
  }

  .badge-col {
    font-size: 13px;
    border-radius: $badge-radius;
    vertical-align: middle;
    padding: 5px 5px 7px 5px;
    background-color: rgba(0, 138, 0, 0.37);
    border: 2px solid rgba(0, 134, 0, 0.89);

  }

  .negative {
    background-color: rgba(138, 0, 0, 0.37);
    border: 2px solid rgba(134, 0, 0, 0.89);
  }

  .message-col {
    text-decoration: underline;
    cursor: pointer;
    .btn-primary{
      font-size: 12px;
      height: 24px;
      width:66px;
    }
  }

}

.admin{
  &-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding:0px 0px 10px 0px;
    h1{
      padding-bottom: 0px;
    }
  }
  &-tabs{
    color: $secondary-white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    &-item{
        cursor: pointer;
        padding: 0px 5px 5px 5px;
        margin: 5px;
        border-bottom: 1px solid $secondary-white;
    }
    &-item-selected{
        color: $white;
        border-bottom: 1px solid $white;
    }
  }
  &-search{
    width:320px;
    h3{
      color: $white;
    }

  }
  &-message{
    &-modal {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      padding: 20px;
      div:nth-child(1) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      img {
        height: $msg-image-size;
        width: $msg-image-size;
        margin-right: 15px;
        margin-bottom: 15px;
      }
    
      &-actions {
        display: flex;
        flex-direction: row;
        margin: auto;
        width: 250px;
        button{
          margin: 5px;
        }
      }
    }
  }
  &-stats{
    &-influencers{
      h3{
        color: $white;
        padding: 10px 0px 10px 20px;
      }
      margin: 20px 0px;
      border-radius: 10px;
      background-color: $primary-grey;
        &-row{
          display:flex;
          flex-direction: row;
          justify-content: space-between;
          padding: 5px 40px 10px 40px;
        }
    }

    &-brands{
      @extend .admin-stats-influencers;
      &-row{
        @extend .admin-stats-influencers-row;
      }
    }

    &-agents{
      @extend .admin-stats-influencers;
      &-row{
        @extend .admin-stats-influencers-row;
      }
    }
  }

  &-campaigns{
    &-modal{
      &-close{
        @extend .modal-offer-close;
      }
      &-header{
        @extend .modal-offer-header;
      }
      &-breadcrumbs{
        @extend .modal-offer-breadcrumbs;
      }
      &-selection{
        @extend .modal-offer-selection;
        h3{
          padding: 10px;
          font-weight: $bold;
        }
        p{
          padding: 10px 0px 10px 20px;
        }
        &-selected{
          @extend .modal-offer-selection-selected;
        }
        &-tile{
          @extend .modal-offer-selection-tile;
        }
        &-row{
          display: flex;
          flex-direction: row;
          .btn-tertiary{
            font-size: 14px;
            width:10px;
            height: 34px;
            margin:0px 0px 0px 14px;
          }
          .btn-tertiary:hover{
            color:$tertiary-grey;
          }
          .btn-tertiary:focus{
            color:$tertiary-grey;
          }
          .btn-tertiary:disabled{
            color:$secondary-grey;
          }
          &-header{
            font-weight: 700;
          }
          &-data{
            p{
              display:flex;
              flex-direction: row;
              margin: 0px 0px 0px 40px;
              div{
                margin: 0px 6px 0px 0px;
              }
            }
          }
        }
      }
      &-footer{
        @extend .modal-offer-footer;
      }
      &-btn{
        @extend .modal-offer-btn;
      }
    }
  }
}