.onboarding{
    background-color: $primary-grey;
    width: 510px;
    margin: auto;
    text-align: center;
    padding: 50px 80px;
    border-radius: 8px;

    h5{
        padding: 5px;
        color: $white;
    }

    &-forgot{
        margin: 15px 0px 15px 0px;
        font-size: 12px;
        line-height: 16px;
        font-weight: bold;
    }

    &-signUp{
        &-img{
            width:510px;
            position: relative;
            left: -80px;
            top: -50px;
            border-radius: 8px 8px 0px 0px;
        }
        &-title{
            font-size:18px;
            line-height: 24px;
            margin: 0px 0px 10px 0px;
            span{
                font-weight: 700;
            }  
        }
        &-blocked{
            &-contact{
                width:100%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                margin: 6px 0px 0px 0px;
                p{
                    color: $white;
                    font-size: 12px;
                    font-weight: 700;
                    line-height: 16px;
                    margin: 0px 10px 0px 0px;
                }
                .btn-quaternary{
                    width:100%;
                    font-size: 12px;
                    font-weight: 700;
                    line-height: 16px;
                    margin:0px;
                    border-radius: 0px;
                }
            }
        }
    }
    
    &-linebreak{
        text-align: center; 
        border-bottom: 2px solid $secondary-grey; 
        line-height: 0.1em;
        margin: 10px 40px 20px;
        
        span{
            color: $white;
            background:$primary-grey; 
            font-size: 10px;
            padding:0 10px; 
        }
    }

    &-txt{
        color: $white;
        padding: 20px 0 8px;
        line-height: 16px;
        font-size: $small;
        font-weight: $bold;
    }

    &-btn-container{
        width: 30%;
        margin:auto;
    }

    &-complete{
        display: flex;
        flex-direction: column;
        padding-top: 100px;
        text-align: center;
        animation: fadeOut 1s forwards;
        animation-delay: 4s;
        span{
            color: $white;
        }
        &-mobile{
            @extend .onboarding-complete;
            margin: 30px;
            animation: none;
            animation-delay: none;
        }
    }
    
    &-complete-icon{
        font-size: 64px;
        padding-top: 18px;
    }

    &-skip{
        display: flex;
        flex-direction: row;
        margin: 15px 0px 0px 0px;
        .btn-tertiary{
            margin: 8px 20px 0px 0px
        }
    }

    &-resend{
        .btn-quaternary{
            display: flex;
            flex-direction: row;
            justify-content: center;
        }
    }

    &-keywords-container{
        width: 100%;
        height: 310px;
        display: flex;  
        flex-wrap: wrap;
        align-content: flex-start;
        justify-content: center;
        overflow-y:hidden;
        -webkit-mask-image: linear-gradient(to bottom, $primary-grey 80%, transparent 100%);
        mask-image: linear-gradient(to bottom, $primary-grey 80%, transparent 100%);
    }

    &-keyword{
        height:30px;
        color: $white;
        cursor: pointer;
        border: 2px solid $white;
        border-radius: 20px;
        padding: 4px 12px;
        margin: 5px;
        &:hover,
        &:focus{
          background: $secondary-grey;
          span{
            color: $white;
          }
        }
    }
    
    &-keyword-selected{
        @extend .onboarding-keyword;
        background: $white;
        span{
          color: $primary-grey;
        }
    }

    &-socials{
        padding-bottom: 24px;
    }

    &-signInBtn {
        margin: 28px 0px 0px 0px;
    }

    &-search {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 80%;
        background-color: $secondary-grey;
        border-radius: 18px;
        margin: 10px 41px 20px 41px;
        svg{
            color: $white;
            margin: 8px 0px 6px 20px;
        }
        .css-2b097c-container{
            width: 100%;
            padding: 5px;
            
            .css-1qiedc7-Control{
                padding: 0px;
            }
        }
    }

    &-captcha{
        position: relative;
        left:20px;
        margin: 0px 0px 15px 0px;
    }

    &-type{
        &-select{
            -webkit-appearance: button;
            -moz-appearance: button;
            -webkit-user-select: none;
            -moz-user-select: none;
            -webkit-padding-start: 2px;
            -moz-padding-start: 2px;
            background-color: $secondary-grey; /* Fallback color if gradients are not supported */
            // background-image: url(../images/select-arrow.png), -webkit-linear-gradient(top, #E5E5E5, #F4F4F4); /* For Chrome and Safari */
            // background-image: url(../images/select-arrow.png), -moz-linear-gradient(top, #E5E5E5, #F4F4F4); /* For old Firefox (3.6 to 15) */
            // background-image: url(../images/select-arrow.png), -ms-linear-gradient(top, #E5E5E5, #F4F4F4); /* For pre-releases of Internet Explorer  10*/
            // background-image: url(../images/select-arrow.png), -o-linear-gradient(top, #E5E5E5, #F4F4F4); /* For old Opera (11.1 to 12.0) */
            // background-image: url(../images/select-arrow.png), linear-gradient(to bottom, #E5E5E5, #F4F4F4); /* Standard syntax; must be last */
            background-position: center right;
            background-repeat: no-repeat;
            border: 1px solid $secondary-grey;
            //border-radius: 2px;
            //box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
            color: $white;
            font-size: 18px;
            margin: 0px 0px 10px 0px;
            overflow: hidden;
            padding: 6px 20px 6px 10px;
            text-overflow: ellipsis;
            white-space: nowrap;
            border-radius: 24px;
        }
    }



}

.onboarding-wide{
    @extend .onboarding;
    padding: 50px 50px 30px 50px;
}

.onboarding-xtrawide{
    @extend .onboarding-wide;
    width: 800px;
    padding: 50px 40px 20px 40px;
    h1{
        margin: 0px 0px 10px 0px
    }
    &-container{
        background-color: $primary-grey;
        width: 600px;
        margin: auto;
        text-align: center;
        padding: 50px 80px;
        border-radius: 8px;
    }
    &-row{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        color: $white
    }
    &-column{
        width: 100%;
        margin: 20px;
        &-content-starter{
            background-color: $secondary-grey;
            padding: 20px 20px;
            border-radius: 8px;
        }
        &-content-plus{
            background-color: $orange;
            padding: 20px 20px;
            border-radius: 8px;
            .btn-primary{
                &:hover,
                &:focus{
                    border: 2px solid $secondary-grey;
                    background: $secondary-grey;
                    color: $white;
                }
            }
        }
        &-list{
            display: flex;
            flex-direction: column;
            justify-content: center;
            &-header{
                display: flex;
                flex-direction: column;
                margin: 0px 0px 15px 0px;
                p{
                    display: flex;
                    justify-content: center;
                    font-weight: bold;
                    font-size: 28px;
                    line-height: 33px; 
                    padding: 0px;
                }
                span{
                    font-weight: 500;
                    font-size: 12px;
                }
            }
            &-content{
                display: flex;
                flex-direction: column;
                text-align: left;
                span{
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 16px;
                    padding: 0px 0px 8px 0px
                }
            }
        }
        button{
            margin: 15px 0px;
            width: 112px;
            height: 32px;
        }
    }
    &-disclaim{
        display: flex;
        justify-content: center;
        margin: 10px 0px 0px 0px;
        color:$white;
        font-weight: 500;
        font-size: 12px;
        line-height: 157.4%;
    }
}




