$orange: #C274F5;
$secondary-orange: #9A6A47;
$white: #F1F1F1;
$secondary-white: #929292;
$tertiary-white: #E6E6E6;
$quaternary-white: #E5E5E5;
$primary-grey: #2E2E2E;
$secondary-grey: #474747;
$tertiary-grey: #727272;
$primary-text: #3A3A3A;
$error: #EB5757;
$background: #1A1A1A;
$success: #149A44;
$delivered: #017329;
$exclusion: #E22323;
$women: #FF5C8A;
$men: #0067FC;

// SOCIAL
$fb-blue: #365899;
$tw-blue: #1DA1F2;
$ig-gradient: linear-gradient(90deg, #F1F1F1 0%, #4D5BD7 0.01%, #CF2397 100%);
$yt-red: #CC0000;

// FONT SIZES
$x-small: 12px;
$small: 14px;
$medium: 16px;
$large: 20px;
$x-large: 24px;

// FONT WEIGHT
$light: 400;
$bold: 700;