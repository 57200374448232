@media only screen and (max-width: 1200px) {
	main {
		margin: 120px 100px;
	}
}

@media only screen and (max-width: 1024px) {
	main {
		margin: 120px 30px 0;
	}
}

@media only screen and (max-width: 768px) {
	main {
		margin: 100px 10px 0;
	}
}

@media only screen and (max-width: 425px) {
	main {
		margin: 100px 0px 0;
	}
}
