button{
    border: none;
    border-radius: 24px;
    font-weight: 700;
    margin: 8px 0;
    text-transform: capitalize;
    height: 48px;
    width: 100%;
    &:disabled{
        background: $primary-grey;
        border: 2px solid $primary-grey;
        &:hover,
        &:focus{
            background: $primary-grey;
            border: 2px solid $primary-grey;
            color: $secondary-grey;
        }
    }
}

.btn-container{
    width: 157px
}
.btn-primary{
    @extend button;
    background:$white;
    color: $secondary-grey;
    &:hover,
    &:focus{
        background: linear-gradient(83.66deg, #9A00FF 0%, #FE00EE 100%);
        color: $white;
        .fa-gradient{
            background:$white;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }
}

.btn-primary-sm {
    @extend .btn-primary;
    height:28px;
    font-size: small;
    padding-left: 10px;
    padding-right: 10px;
}

.btn-secondary{
    @extend button;
    background:$secondary-grey;
    color: $white;
    &:hover,
    &:focus{
        background: linear-gradient(83.66deg, #9A00FF 0%, #FE00EE 100%);
        color: $white;
    }
}

.btn-secondary-sm {
    @extend .btn-secondary;
    height:28px;
    font-size: small;
    padding-left: 10px;
    padding-right: 10px;
    &:disabled{
        color: $secondary-grey;
    }
}

.btn-tertiary{
    @extend button;
    cursor: pointer;
    padding: 0;
    margin: 8px 0;
    background: none;
    border: none;
    color: $white;
    &:disabled{
        color: $primary-grey;
        background: none;
        border: none;
    }
    &:hover,
    &:focus{
        background: none;
        border: none;
        color: $primary-grey;
    }
}

.btn-quaternary{
    display: flex;
    justify-content: left;
    font-weight: 700;
    height: 24px;
    cursor:pointer;
    line-height: 1.3;
    background: radial-gradient(26.21% 193.75% at 16.03% 0%, #9A00FF 0%, #CE00F6 16.67%, #FE00EE 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    &:hover,
    &:focus{
        background: linear-gradient(45deg, #ffffff, #fe00ee);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}

// .btn-tertiary{
//     @extend button;
//     cursor: pointer;
//     padding: 0;
//     margin: 8px 0;
//     background: none;
//     border: 2px solid $background;
//     color: $white;
//     &:hover,
//     &:focus{
//         background: none;
//         border: 2px solid $white;
//         color: $tertiary-grey;
//     }
//     &:disabled{
//         color: $primary-grey;
//         background: none;
//         border: none;
//     }
// }



// SOCIAL
.btn-fb{
    @extend button;
    background:$fb-blue;
    border: 2px solid $fb-blue;
    color: $white;
    &:hover,
    &:focus{
        border: 2px solid $white;
    }
}

.btn-tw{
    @extend button;
    background: $tw-blue;
    color: $white;
    &:hover,
    &:focus{
        border: 2px solid $white;
    }
}

.btn-ig{
    @extend button;
    background: $ig-gradient;
    border: 2px solid $ig-gradient;
    color: $white;
    &:hover,
    &:focus{
        border: 2px solid $white;
    }
}

.btn-yt{
    @extend button;
    background:$yt-red;
    color: $white;
    &:hover,
    &:focus{
        border: 2px solid $white;
    }
}

