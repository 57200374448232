/* INPUT */
input[type='checkbox'],
input[type='radio']{
    padding: 20px;
    margin: 0 5px;
    &:checked{
        background: $white;
    }
}

input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

input:focus {
    background-color: $secondary-grey;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px $secondary-grey inset;
    -webkit-text-fill-color: $white;
    
}


select{
    -webkit-appearance: none;
    -moz-appearance: none;
}
select:-webkit-autofill,
select:-webkit-autofill:hover, 
select:-webkit-autofill:focus, 
select:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px $secondary-grey inset;
    -webkit-text-fill-color: $white;
}


.label-option{
    display: flex;
    flex-direction: row;
    padding: 5px 0;
    margin-bottom: 3px;
    div{
        display: flex;
    }
    span{
        text-align: left;
        color: $white;
    }
}

textarea{
    border: 2px solid $secondary-grey;
    background:#474747;
    border-radius: 18px;
    color: $white;
    height: 168px;
    width: 100%;
    padding: 8px 16px;
    margin: 8px auto;
}

.textarea-count{
    text-align: right;
    padding: 15px;
    margin-top: -50px;
    margin-bottom: 30px;
}

.textarea-error{
    border: 2px solid $error;
}

.form{
    &-input{
        border: none;
        border-radius: 18px;
        background:#474747;
        color: $white;
        height: 36px;
        width: 100%;
        padding: 7px 10px;
        margin: 8px 0px 20px 0px;

        &-file-upload{
            margin: 15px auto;
            cursor: pointer;
            color: $white;
            width: 80px;
            height: 80px;
            text-align: center;
            border-radius: 50%;
            background-color: $secondary-grey;
            .upload-placeholder{
                padding-top: 25px;
            }
            div{
                img{
                    object-fit: cover;
                    border-radius: 50%;
                }
            }
            img{
                width: 80px;
                height: 80px;
                text-align: center;
            }
        }

        &-mastheading{
            &-title{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                span{
                    font-size: 14px;
                    line-height: 24px;
                }

            }
            &-upload{
                margin: 15px auto;
                cursor: pointer;
                color: $white;
                background:$secondary-grey;
                border-radius: 8px;
                width: 100%;
                p{
                    text-align:center;
                    padding: 75px;
                }
                img{
                    width: 100%;
                    height: 160px;
                    border-radius: 8px;
                    object-fit: cover;
                }
            }
            &-dash{
                border: 1px dashed $white;
            }
            &-error{
                border: 2px solid $error;
            }
        }
    }

    &-input input,
    &-textarea textarea{
        background:$secondary-grey;
        border: none;
        color: $white;
        padding: 0 10px;
        height: 100%;
        width: 85%;
    }
    
    &-input-error{
        border: 2px solid $error;
        animation: transitionBorder 2s forwards;
        animation-delay: 5s;
    }

    &-checkbox-error{
        input[type='checkbox']{
            outline: 1px solid $error !important;
        }
        span{
            color: $error;
        }
    }

    &-error-icon{
        position: absolute;
        color: $error;
        animation: fadeOut 2s forwards;
        animation-delay: 5s;
    }
}

#offer-form{
    margin: 0px 0px 20px 0px;
}

@keyframes transitionBorder {
    from {border: 2px solid $error;}
    to {border: 2px solid $secondary-grey;}
}

@keyframes transitionPlaceholder {
    from {color: $error;}
    to {color:#999;}
}

