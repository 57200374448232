@media all and (max-width: 768px) {
    .home{
        margin:90px;
        &-inline{
            flex-direction: column;
        }
        &-campaigns{
            width: 100%;
        }
        &-inbound{
            width: 100%;
        }
    }
}

@media all and (max-width: 425px) {
    .home-mobile{
        margin: 20px;
        h3{
            color: $white;
        }
        &-btn{
            margin: auto;
            width: 50%
        }
        // h1 {
        //     text-align: center;
        //     padding: 0px 0px 0px;
        // }
        // &-campaigns{
        //     padding: 0px 0px 0px;
        //     background-color: $background;
        // }
        // &-campaign{
        //     display: flex;
        //     flex-direction: column;
        //     justify-content:space-between;
        //     text-align: center;
        //     background-color: $primary-grey;
        //     width: 85%;
        //     margin: 0px;
        //     margin-top: 15px;
        //     &-actions{
        //         margin: auto;
        //     }
        // }
        // &-btn-container {
        //     width: 50%;
        // }
        // &-inbound {
        //     background-color: $background;
        //     h2{
        //         text-align: left;
        //     }
        
        // }
    }
}

@media all and (max-width: 320px) {
    .home-mobile{
        &-campaign{
            width: 100%;
        }
    }
}