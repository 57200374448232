.sidebar-tile{
    margin: 0 0 10px 0;
    background: $primary-grey;
    border-radius: 8px;
    padding: 21px 18px 21px 24px;
    max-height:600px;
    span{
        color:$white;
    }
    &-tooltip{
        font-size: $small;
        color: $secondary-white;
        margin: 0 auto;
        text-align: center;
        cursor: pointer;               
    }
    &-btn{
        margin: 0 auto;
        width: 90%;
        button{
            padding: 12px 0;
            margin: 0;
        }
    }
    &-influencer{
        padding: 10px 0;
        img{
            border-radius: 50%;
            object-fit: cover;
            height: 56px;
            width: 56px;
        }
        div{
            display: flex;
            flex-direction: row;
            padding: 5px 0;
            div{
                padding: 0 10px;
                display: block;
                float: left;
                div{
                    padding: 0;
                    a{
                        color: $white;
                        padding: 0 5px;
                    }
                }
            }
            button{
                height: 32px;
                margin: 5px;
            }
        }
    }
    &-content{
        max-height:500px;
        overflow-y: auto;
    }

    span{
        display: block;
        padding: 20px 10px;
        font-weight: $bold;
    }
}

.sidebar-tile-content::-webkit-scrollbar {
    width: 6px;
}
.sidebar-tile-content::-webkit-scrollbar-thumb {
    background: $primary-grey;
}
.sidebar-tile-content::-webkit-scrollbar-track{
    background: $primary-grey;
}
.sidebar-tile-content:hover::-webkit-scrollbar-thumb {
    background: $tertiary-grey; 
}
.sidebar-tile-content:hover::-webkit-scrollbar-track {
    background: $secondary-grey;
}
.sidebar-tile-content::-webkit-scrollbar-thumb:hover {
    background: $white;
}