@media all and (max-width: 768px) {
    .cometchat{
        .unified__sidebar{
            .chats{
                &__list{
                    .list__item{
                        border-radius: 8px;
                        background: $secondary-grey;
                        margin: 20px;
                        padding: 10px;
                        height: 60px;
                        width: 250px;
                    }
    
                }
            }
            
        }
    }
}

@media all and (max-width: 767px) {
    
    //@import '../base/disablescroll';

    .cometchat{
        .unified__sidebar{
            background: $background;
            .chats{
                &__list{
                    .list__item{
                        margin-left: auto;
                        margin-right: auto;
                        width: 300px;
                    }
    
                }
            }
            
        }
        .unified__main{
            min-height: 450px;
            height: auto;
            margin:2px
        }
    }
}

@media all and (max-width: 425px){
    .cometchat{
        .unified__main{

            margin:10px
        }
    }
}

@media all and (max-width: 375px){
    .cometchat{
        .unified__main{
            margin:4px
        }
    }
}

@media all and (max-width: 320px) {
    
    //html, body {margin: 0px; height: 100%; overflow: hidden}

    .cometchat{
        .unified__main{
            margin:2px;
            .main__chat{
                width: 99%;
            }
        }
    }
}
