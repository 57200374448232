footer{
    background: $primary-grey;
    height: 152px;
    padding: 0 200px;
    position: absolute;
    bottom: 0;
    width: 100%;
    color: $white;
    text-align: center;

    div{
        padding-top: 20px;
        font-size: $small;
    }

    nav{
        padding-top: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        ul{
            padding-top: 20px;
            display: flex;
            list-style: none;

            li{
                cursor: pointer;
                margin-left: 40px;
                &:first-child{
                    margin-left: 0;
                }
                &:hover{
                    color: $secondary-white;
                }
                
                a{
                    color: $white;
                    text-decoration: none;
                    &:hover{
                        color: $secondary-white;
                    }
                }
            }
        }
    }
}

