.manage-campaign{
    color: $white;

    &-main{
        display: flex;
        flex-direction: row;
        width: 100%;
    }

    &-header{
        position: relative;
        img{
            width: 100%;
            object-fit: cover;
            height: 240px;
            
        }
        &-content-container{
            background: linear-gradient(180deg, rgba(26, 26, 26, 0.15) 0%, #1A1A1A 100%);
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
        }
        &-content{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 80px;
            button{
                height: 30px;
                margin: 0;
            }
            h2{
                font-size: $medium;
            }
            div{
                a{
                    display: block;
                    padding: 8px;
                    font-weight: $bold;
                }
            }
        }
    }

    &-breadcrumbs{
        width: 28%;
        padding-right: 27px;
    }

    &-breadcrumb{
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-radius: 4px;
        padding: 6px 10px;
        margin: 5px 0;
        color: $tertiary-grey;
        div{
            padding-top: 2px;
        }
        &-active{
            background: $white;
            color: $primary-grey;
        }
        &-title{
            display: flex;
            flex-direction: row;
            font-weight: 700;
        }
        &-icon{
            font-size: 20px;
            margin-right: 8px;
        }
        &-check{
            font-size: 12px;
            margin-top: 4px;
        }
        &-check-complete{
            color: $orange;
        } 
    }

    &-content{
        width: 100%;
        &-main{
            padding: 20px 0;  
            width: 70%;
            &-hired{
                display: flex;
                flex-direction: row;  
                &-item{
                    text-align: center;
                    padding: 10px;
                    img{
                        width: 56px;
                        height: 56px;
                        object-fit: cover;
                        border-radius: 50%;
                    }
                    h4{
                        max-width: 80px;
                    }
                }
            }
            &-review{
                display: flex;
                flex-direction: row;  
                justify-content: space-between;
                width:100%;
                flex-wrap: wrap;
                align-content: flex-start;
                align-items: flex-start;
                max-height: 500px;
                overflow: hidden;
                margin-bottom: 20px;
                -webkit-mask-image: linear-gradient(to bottom, black 90%, transparent 100%);
                mask-image: linear-gradient(to bottom, black 80%, transparent 100%);
                &::-webkit-scrollbar {
                    height: 0.4em;
                   }
                &-item{
                    padding-right: 20px;
                    max-width: 250px;
                    max-height: 370px;
                    &-header{
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        div{
                            display: flex;
                            flex-direction: row;  
                            padding: 5px 0;
                            img{
                                border-radius: 50%;
                                object-fit: cover;
                                height: 32px;
                                width: 32px;
                            }
                            h4{
                                padding: 10px;
                            }
                            svg{
                                margin: 12px 0px 0px 0px;
                            }
                        }
                        &-icons{
                            padding: 0px 0px !important;
                            :nth-child(2){
                                margin:0px 0px 0px 8px;
                                cursor: pointer;
                            }
                        }
                    }
                    &-btns{
                        display: flex;
                        flex-direction: row;
                        margin: 10px 0px 0px 0px;
                        
                        button{
                            height: 32px;
                            padding: 0 10px;
                        }
                        .btn-secondary{
                            margin-left: 10px;
                            margin-right: 10px;
                        }
                    }

                    
                    img{
                        // padding: 5px 0;
                        // width: 245px;
                        width: 230px;
                        max-height: 100%;
                    }
                    iframe{
                        width: 230px;
                        max-height: 100%;
                    }
                    span{
                        font-size: $small;
                        display: block;
                        line-height: 1.15;
                    }


                }
                &-btn{
                    text-align: center;
                    .btn-tertiary{
                        height:32px;
                        width:128px;
                        line-height:24px;
                        font-size: 16px;
                        border: 2px solid #F1F1F1;
                        border-radius: 24px;
                    }
                }
                &-hidden{
                    @extend .manage-campaign-content-main-review;
                    max-height: 100%;
                    overflow: show;
                    -webkit-mask-image: none;
                    mask-image: none;
                }
            }
            &-section{
                display: flex;
                flex-direction: row;
                padding: 0 0 20px 0;
                div{
                    width: 33%;
                    padding-right: 10px;
                }
                div:last-child(){
                    padding: 0
                }
                span{
                    font-size: $small;
                    line-height: 1.5;
                }
            }
        }
    }
    
    &-deliverables{
        &-content{
            width: 100%;
        }
        
        &-table{
            display: flex;
            flex-direction: column;
            background-color: $primary-grey;
            padding: 10px;
            border-radius: 8px;
            overflow-y: auto;
            min-height:150px;
            max-height:250px;
            margin: 0px 0px 20px 0px;
            &-content{
                overflow-y:auto;
                padding: 0px 6px 0px 0px;
            }
            ::-webkit-scrollbar {
                width: 6px;
            }
            ::-webkit-scrollbar-thumb, ::-webkit-scrollbar-track {
                background: $primary-grey;
            }
            :hover::-webkit-scrollbar-thumb {
                background: $tertiary-grey; 
            }
            :hover::-webkit-scrollbar-track {
                background: $secondary-grey;
            }
            ::-webkit-scrollbar-thumb:hover {
                background: $white;
            }
            
            span{
                font-size: 14px;
                color: #E6E6E6;
                display: flex;
                align-items: center;
            }
            &-row{
                display: flex;
                flex-direction: row;
                &-item{
                    display: flex;
                    flex-direction: row;
                    background-color: $secondary-grey;
                    margin: 3px 0px;
                    border-radius: 8px;
                    padding:5px
                }
                h4{
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 16px;
                    padding: 4px 8px 8px 8px;
                }
                &-col1{
                    width: 32%;
                    display: flex;
                    flex-direction: row;  
                    img{
                        border-radius: 50%;
                        object-fit: cover;
                        height: 24px;
                        width: 24px;
                        margin: 0px 5px 0px 0px;
                    }
                }
                &-col2{
                    display: flex;
                    flex-direction: row;  
                    align-items: center;
                    width: 20%;
                    span{
                        font-size: 10px;
                        line-height: 12px;
                        font-weight: bold;
                        border-radius: 10px;
                        padding: 0px 5px;
                        height: 18px;
                    }
                    &-pre{
                        color: $secondary-grey !important;
                        background-color: $white;
                    }
                    &-ready{
                        color: $white !important;
                        background-color: $orange;
                    }
                    &-revision{
                        color: $white !important;
                        background-color: $error;
                    }
                    &-approved{
                        color: $white !important;
                        background-color: $success;
                    }
                    &-delivered{
                        color: $white !important;
                        background-color: $delivered;
                    }
                }
                &-col3{
                    width: 48%;
                    display: flex;
                    flex-direction: row;  
                    align-items: center;
                    svg{
                        margin: 0px 10px 0px 0px
                    }
                }
            }
        }

        &-review{
            display: flex;
            flex-direction: row;  
            justify-content: space-between;
            overflow: auto;
            margin-bottom: 20px;
            width: auto;
            &::-webkit-scrollbar {
                height: 0.4em;
               }
            &-item{
                padding-right: 20px;
                max-width: 300px;
                div{
                    display: flex;
                    flex-direction: row;  
                    padding: 5px 0;
                    img{
                        border-radius: 50%;
                        object-fit: cover;
                        height: 32px;
                        width: 32px;
                    }
                    h4{
                        padding: 10px;
                    }
                    button{
                        height: 32px;
                        padding: 0 10px;
                    }
                    .btn-secondary{
                        margin-right: 10px;
                    }
                }
                img{
                    // padding: 5px 0;
                    // width: 245px;
                    width: 100%;
                    max-height: 100%;
                }
                span{
                    font-size: $small;
                    display: block;
                    line-height: 1.15;
                }

            }
        }
    }

    &-overview{
        display: flex;
        flex-direction: row;
        width: 100%;
        h3,h4{
            font-weight: $bold;
            padding: 5px 0;
        }
        h4{
            font-size: $small;
        }

        &-sidebar{
            padding: 0 20px 0 0;
            width: 30%;
            text-align: center;
        }
    
        &-main{
            padding: 20px 0;  
            width: 70%;
            &-hired{
                display: flex;
                flex-direction: row;  
                &-item{
                    text-align: center;
                    padding: 10px;
                    img{
                        width: 56px;
                        height: 56px;
                        object-fit: cover;
                        border-radius: 50%;
                    }
                    h4{
                        max-width: 80px;
                    }
                }
            }
            &-review{
                display: flex;
                flex-direction: row;  
                justify-content: space-between;
                // overflow: auto;
                margin-bottom: 20px;
                &::-webkit-scrollbar {
                    height: 0.4em;
                   }
                &-item{
                    padding-right: 20px;
                    max-width: 300px;
                    div{
                        display: flex;
                        flex-direction: row;  
                        padding: 5px 0;
                        img{
                            border-radius: 50%;
                            object-fit: cover;
                            height: 32px;
                            width: 32px;
                        }
                        h4{
                            padding: 10px;
                        }
                        button{
                            height: 32px;
                            padding: 0 10px;
                        }
                        .btn-secondary{
                            margin-right: 10px;
                        }
                    }
                    img{
                        // padding: 5px 0;
                        // width: 245px;
                        width: 100%;
                        max-height: 100%;
                    }
                    span{
                        font-size: $small;
                        display: block;
                        line-height: 1.15;
                    }

                }
            }
            &-row{
                display: flex;
                flex-direction: row;
            }
            &-section{
                display: flex;
                flex-direction: column;
                align-items: center;
                background-color: $primary-grey;
                margin: 8px;
                padding: 12px 20px 20px 20px;
                border-radius: 8px;
                span{
                    font-size: 18px;
                    line-height: 24px;
                }
                &-third{
                    @extend .manage-campaign-overview-main-section;
                    width: 100%;
                    height: 100%;
                    &-row{
                        width:100%;
                        h4{
                            font-weight: 700;
                            font-size: 12px;
                            line-height: 16px;
                            margin: 0px 0px 8px 0px;
                        }
                        span{
                            display:block;
                            font-size: 12px;
                            line-height: 16px;
                            margin: 0px 0px 16px 0px;
                        }
                        #truncate-text{
                            font-size: 12px;
                            line-height: 16px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2; /* number of lines to show */
                                    line-clamp: 2; 
                            -webkit-box-orient: vertical;
                        }
                        #regular-text{
                            font-size: 12px;
                            line-height: 16px;
                        }
                        .btn-tertiary{
                            height:0px;
                            margin: auto;
                            font-weight: 700;
                            font-size: 12px;
                            line-height: 16px;
                            &:hover{
                                color:$secondary-white;
                            }
                        }
                    }
                }
            }
        }
    }

    &-influencers{
        padding: 20px 0;  
        width: 100%;
        &-hired{
            padding: 20px 0;  
            width: 100%;
            &-content{
                display: flex;
                flex-direction: row; 
                &-item{
                    text-align: center;
                    padding: 10px 5px;
                    max-width: 110px;
                    overflow: hidden;
                    img{
                        width: 56px;
                        height: 56px;
                        object-fit: cover;
                        border-radius: 50%;
                    }
                }
            }
        }
        &-main{
            display: flex;
            flex-direction: row;
            width: 100%;
            text-align: center;
            .sidebar-tile{
                margin: 0px 20px 0px 0px;
                width:33%
            }
        }

    }

    &-contracts{
        &-none{
            display: flex;
            flex-direction: column;
            text-align: center;
            h3{
                padding: 30px 100px;
                line-height: 24px;
            }
            .btn-primary{
                margin: auto;
                max-width: 216px;
            }
        }

        &-main{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            &-influencer{
                width: 200px;
                height:250px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin: 5px 5px;
                padding: 22px 0px;
                background-color: $primary-grey;
                border-radius: 8px;
                a{
                    margin: auto;
                }
                img{
                    border-radius: 50%;
                    object-fit: cover;
                    height: 56px;
                    width: 56px;
                }
                &-title{
                    margin:auto;
                    display: flex;
                    flex-direction: row;
                    padding: 5px 0;
                    div{
                        padding: 0 10px;
                        display: block;
                        float: left;
                        div{
                            padding: 0;
                            a{
                                color: $white;
                                padding: 0 5px;
                            }
                        }
                    }
                }
                &-payment{
                    flex-direction: column ;
                    margin: 0px auto;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 16px;
                    padding: 5px 10px 20px 10px;
                    span{
                        color: $secondary-white;
                    }

                }
                button{
                    margin: auto;
                    width: 132px;
                    height: 32px;
                }
            }
        }

    }
}

