.progress-circle{
    padding: 10px 50px;
    font-size: $x-large;
    font-weight: $bold;
    &-text{
        padding-bottom: 10px;
    }
    .CircularProgressbar{
        color: $white;
        width: 90px;
        &-path{
            stroke: $orange;
            stroke-linecap: round;
        }
        &-trail{
            stroke: $secondary-grey;
        }
        &-text{
            fill: $white;
        }
    }
}


    