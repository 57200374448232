.error{
    display:flex;
    justify-content: center;
    margin-top: 200px;
    &-card{
        width:430px;
        p{
            font-size: 16px;
            line-height: 20px;
        }
        &-btn{
            &-container{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                button{
                    margin: 10px;
                }
            }
        }

    }
}